const emotions1 = [
    { description: "excited", label: "excited" },
    { description: "happy", label: "happy" },
    { description: "exhausted", label: "exhausted" },
    { description: "anxious", label: "anxious" },
    { description: "frustrated", label: "frustrated" },
    { description: "content", label: "content" },
    { description: "so tired that I need some downtime to recuperate", label: "need_recuperate" },
    // { description: "physically recovered", label: "physically_recovered" },
    // { description: "mentally recovered", label: "mentally_recovered" },
];

const emotions2 = [
    { description: "how happy do you feel?", label: "happy", itemNum: 5 },
    { description: "do you feel that your life has a clear sense of purpose?", label: "sense_purpose", itemNum: 6 },
    { description: "how connected to other people do you feel?", label: "connected_others", itemNum: 7 },
    { description: "how lonely do you feel?", label: "lonely", itemNum: 8 },
];

emotions1.map(item => {
    item.numericVal = null;
});

emotions2.map(item => {
    item.numericVal = null;
});

export { emotions1, emotions2 }