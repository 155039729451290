<template>
  <ion-app>
    <ion-progress-bar :value="progress" v-if="shouldShowProgressBar"></ion-progress-bar>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { provide, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { IonApp, IonRouterOutlet, IonProgressBar } from '@ionic/vue';
import { useProgress } from '@/modules/useProgressBar';

export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonProgressBar
  },
  setup() {
    const store = useStore();
    const { progress, shouldShowProgressBar, updateProgress, showProgressBar } = useProgress();
    const subjectID = computed(() => store.state.subjectID);
    const participantID = computed(() => store.state.participantID);
    const responseID = computed(() => store.state.responseID);
    const sampleNum = computed(() => store.state.sampleNum);
    const sampleNumToday = computed(() => store.state.sampleNumToday);
    const mode = ref("ios");
    
    provide('progress', progress);
    provide('updateProgress', updateProgress);
    provide('showProgressBar', showProgressBar);
    provide('subjectID', subjectID);
    provide('participantID', participantID);
    provide('responseID', responseID);
    provide('sampleNum', sampleNum);
    provide('sampleNumToday', sampleNumToday);
    provide('mode', mode);

    return {
      shouldShowProgressBar,
      progress,
    }
  },
};
</script>

<style>
html, body {
    overscroll-behavior-x: none;
}

.range-label-wrapper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 100%;
    align-items: center;
    position: relative;
}

.flex-label-row {
  display: flex;
  justify-content: space-between;
}

.range-label-row {
    display: grid;
    grid-auto-columns: minmax(max-content, 2fr);
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
}

.range-label-text {
    color: #7c7c7c;
    font-size: 0.8rem;
}

ion-range.has-val::part(knob) {
  --knob-background: rgb(var(--ion-color-primary-rgb));
}

</style>