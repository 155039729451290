<template>
    <main-layout 
        title="Current state" 
        :data="visibleDataOnly"
    >
        <questionnaire-item-view :items="items" @itemUpdated="updateVal"></questionnaire-item-view>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayoutV2.vue'
import QuestionnaireItemView from '@/components/QuestionnaireItemView.vue'
import { emotions } from '@/data/v2/emotions'
import { defineComponent, inject, reactive } from 'vue'
import useItemResponder from '@/modules/useItemResponder'

export default defineComponent({
    name: "emotions-1",
    components: {
        MainLayout,
        QuestionnaireItemView
    },
    setup() {
        const items = reactive(emotions)
        const mode = inject("mode")

        const { updateVal, visibleDataOnly } = useItemResponder(items, 'complex')

        return {
            mode,
            items,
            visibleDataOnly,
            updateVal
        }
    }
});
</script>