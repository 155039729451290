<template>
  <validation-layout 
    title="Compassion in everyday life"
    :data="visibleDataOnly"
  >
    <questionnaire-item-view :items="items" @itemUpdated="updateVal"></questionnaire-item-view>
  </validation-layout>
</template>

<script>
import ValidationLayout from '@/layouts/ValidationLayout.vue'
import { defineComponent, reactive } from 'vue'
import { everydaylife1 } from '@/data/everydaylife.js'
import useItemResponder from '@/modules/useItemResponder'
import QuestionnaireItemView from '@/components/QuestionnaireItemView.vue'

export default defineComponent({
  name: 'everyday-life-1',
  components: {
    ValidationLayout,
    QuestionnaireItemView,
  },
  setup() {
    const items = reactive(everydaylife1);
    const { visibleDataOnly } = useItemResponder(items, 'complex')

    const updateVal = (e, index) => items[index].numericVal = e.target.value

    return {
      items,
      visibleDataOnly,
      updateVal
    }
  },
})

</script>