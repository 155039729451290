export default [
    { description: "Rest/Sleep", icon: "bed", textVal: "rest/sleep" },
    { description: "Working", icon: "business", textVal: "work" },
    { description: "Home computer", icon: "desktop", textVal: "home_computer" },
    { description: "Commuting, traveling", icon: "bus", textVal: "commute/travel" },
    { description: "Grooming, self-care", icon: "cut", textVal: "groom/self_care" },
    { description: "Listening to radio, news", icon: "newspaper", textVal: "radio/news" },
    { description: "Doing housework", icon: "file-tray-full", textVal: "housework" },
    { description: "Watching television", icon: "tv", textVal: "tv" },
    { description: "Reading", icon: "library", textVal: "reading" },
    { description: "Relaxing, nothing special", icon: "cafe", textVal: "relax" },
    { description: "Taking care of children", icon: "happy", textVal: "children" },
    { description: "Shopping, errands", icon: "bag", textVal: "shop/errands" },
    { description: "Preparing food", icon: "fish", textVal: "food_prep" },
    { description: "Praying/worshipping/meditating", icon: "rose", textVal: "prayer/worship/meditate" },
    { description: "Eating", icon: "pizza", textVal: "eat" },
    { description: "Walking, taking a walk", icon: "walk", textVal: "walk" },
    { description: "Listening to music", icon: "headset", textVal: "music" },
    { description: "Playing", icon: "balloon", textVal: "play" },
    { description: "Talking, conversation", icon: "chatbubbles", textVal: "talk/converse" },
    { description: "Exercising", icon: "barbell", textVal: "exercise" },
    { description: "Making love", icon: "heart", textVal: "make_love" },
    { description: "Other", icon: "sparkles", textVal: "other" },
];