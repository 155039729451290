const emotions = [
    { 
        description: "Right now, I feel excited", 
        label: "excited",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 5,
        isRequired: true,
        options: ["Not at all", "Slightly", "Somewhat", "Moderately", "Extremely"]
    },
    { 
        description: "Right now, I feel happy", 
        label: "happy" ,
        numericVal: null,
        type: "slider",
        min: 1,
        max: 5,
        isRequired: true,
        options: ["Not at all", "Slightly", "Somewhat", "Moderately", "Extremely"]
    },
    { 
        description: "Right now, I feel exhausted", 
        label: "exhausted",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 5,
        isRequired: true,
        options: ["Not at all", "Slightly", "Somewhat", "Moderately", "Extremely"]
    },
    { 
        description: "Right now, I feel anxious", 
        label: "anxious",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 5,
        isRequired: true,
        options: ["Not at all", "Slightly", "Somewhat", "Moderately", "Extremely"]
    },
    { 
        description: "Right now, I feel frustrated", 
        label: "frustrated",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 5,
        isRequired: true,
        options: ["Not at all", "Slightly", "Somewhat", "Moderately", "Extremely"]
    },
    { 
        description: "Right now, I feel content", 
        label: "content",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 5,
        isRequired: true,
        options: ["Not at all", "Slightly", "Somewhat", "Moderately", "Extremely"]
    },
    { 
        description: "Right now, I feel so tired that I need some downtime to recuperate", 
        label: "need_recuperate",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 5,
        isRequired: true,
        options: ["Not at all", "Slightly", "Somewhat", "Moderately", "Extremely"]
    },
    {
        description: "How many people have you interacted with in the last 30 minutes?",
        description2: "Including face-to-face, telephone, text message, or via social media, etc.",
        label: "num_people",
        textVal: null,
        type: "radio",
        isRequired: true,
        options: [
            { 
                description: "None", 
                val: "0",
            },
            { 
                description: "1 - 2", 
                val: "1-2",
            },
            { 
                description: "3 - 5", 
                val: "3-5",
            },
            { 
                description: "5 - 10", 
                val: "5-10",
            },
            { 
                description: "More than 10", 
                val: "10+",
            },
        ]
    }
]

const itemNum = 4;
emotions.map((item) => {
    if (!Object.keys(item).includes("isRequired")) item.isRequired = false;
    if (!Object.keys(item).includes("type")) item.type = null;
    if (!Object.keys(item).includes("itemNum")) item.itemNum = itemNum;
})

export { emotions }