<template>
    <validation-layout 
        title="Acceptability" 
        :data="data"
    >
        <div v-for="(item, index) in acceptabilityItems || []" :key="item.label">
            <ion-list-header :mode="mode">
                <ion-label>{{ item.description }}
                    <p class="item-subtext" v-if="item.hasOwnProperty('subtext')">{{ item.subtext ?? null }}</p>
                </ion-label>
            </ion-list-header>
            <ion-range 
                :name="item.label" 
                :class="[item.numericVal !== null ? 'has-val' : false]" 
                :mode="mode" 
                min="1" 
                max="5" 
                color="secondary" 
                step="1" 
                pin="true" 
                snaps="true" 
                @touchend="updateVal($event, index)" 
                @mouseup="updateVal($event, index)"
            >
                <ion-text slot="start"></ion-text>
                <ion-text slot="end"></ion-text>
            </ion-range>
            <div class="range-label-wrapper ion-padding-horizontal">
                <div class="flex-label-row range-label-text">
                    <div class="range-label-text">Completely disagree</div>
                    <div class="range-label-text">Neutral</div>
                    <div class="range-label-text">Completely agree</div>
                </div>
            </div>
        </div>
        <div v-for="item in optionalItems" :key="item.label">
            <ion-list-header :mode="mode">
                <ion-label>{{ item.description }}</ion-label>
            </ion-list-header>
            <ion-item>
                <ion-textarea rows="6" :auto-grow="true" enterkeyhint="done" inputmode="text" placeholder="Type here..." v-model="item.textVal"></ion-textarea>
            </ion-item>
        </div>
    </validation-layout>
</template>

<script>
import ValidationLayout from "@/layouts/USValidationLayout.vue"
import { acceptability } from "@/data/acceptability.js"
import { omit } from 'lodash-es'
import { defineComponent, inject, reactive, computed } from 'vue'
import { IonRange, IonText, IonListHeader, IonLabel, IonItem, IonTextarea } from '@ionic/vue'
import useItemResponder from '@/modules/useItemResponder'

export default defineComponent({
    name: "Acceptability-Page",
    components: {
        ValidationLayout,
        IonRange,
        IonText,
        IonListHeader,
        IonLabel,
        IonItem,
        IonTextarea
    },
    setup() {
        const mode = inject("mode");
        
        const acceptabilityItems = reactive(acceptability.map(item => {
            item.itemNum = 6;
            item.numericVal = null;
            return item;
        }));

        const optionalItems = reactive([
            {
                itemNum: 7,
                description: "If you have any other thoughts or comments, please leave them in the space below:",
                label: "acceptability_other",
                textVal: "",
            }
        ]);

        const updateVal = (e, index) => acceptabilityItems[index].numericVal = e.target.value;

        useItemResponder(acceptabilityItems, 'numeric')

        const data = computed(() => {
            let items = [
                ...acceptabilityItems,
                ...optionalItems,
            ];

            return items.map(item => omit(item, ['subtext']));
        });

        return {
            mode,
            data,
            acceptabilityItems,
            optionalItems,
            updateVal,
        };
    }
});
</script>