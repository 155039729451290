<template>
    <ion-page>
        <ion-content>
            <youtube-iframe 
                  :video-id="videoId" 
                  :no-cookie="true"
                  :player-width="`100%`"
                  :player-height="`300px`"
                  :player-parameters="{ host: `${proto}//www.youtube.com` }">
            </youtube-iframe>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle>Understanding our three emotion systems</ion-card-subtitle>
              <ion-card-title>Three Circles: A brief introduction</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-row>
                <ion-text id="tutorial-text">
                  <p>This app is a simple and interactive way to introduce people to the Three Circle model from Compassion Focused Therapy.</p>
                  <p>The video above provides a brief (&lt;3 mins) overview of our Threat, Drive and Soothing systems.</p>
                  <h3>Using the app</h3>
                  <p>This app is experimental and currently very basic. You can use it to play around with the size of the circles with a simple click (or tap) and drag.</p>
                  <h3>What would I use it for?</h3>
                  <p>Anything you like. We have found it is a useful way to illustrate the concepts. For example, you could use it as a simple way to report on your own circles, similar to drawing them on paper in a therapy session.</p>
                  <h3>What's next?</h3>
                  <p>Our team is currently running a validation study to determine whether the digital three circles app provides a useful and valid measure of wellbeing.</p>
                  <p>This will form part of a larger app (currently in development) designed to support people as they learn about compassion, try new skills and monitor their change over time.</p>
                  <h3>Who can I contact?</h3>
                  <p>This app was developed by Dr Jamin Day and Dr James Kirby from the University of Queensland's Compassionate Mind Research Group.</p>
                  <p>If you have any questions or would like to consider using this in your own research, contact Dr Jamin Day at (<a href="mailto:j.day@psy.uq.edu.au">j.day@psy.uq.edu.au</a>).</p>
                </ion-text>
              </ion-row>
              <ion-row class="ion-padding-top">
                <ion-button @click="closeModal">
                  <ion-icon name="check" slot="start"></ion-icon>Close
                </ion-button>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonButton, IonRow, IonIcon, IonText, modalController } from '@ionic/vue';
import { addIcons } from 'ionicons';
import { checkmarkOutline  } from 'ionicons/icons';

export default {
  name: "Tutorial-Page",
  components: {
    IonPage, 
    IonContent, 
    IonCard, 
    IonCardHeader, 
    IonCardTitle, 
    IonCardSubtitle,
    IonCardContent,
    IonButton,
    IonRow,
    IonIcon, 
    IonText,
  },
  setup() {
    const proto = window.location.protocol;
    const videoId = "CUBloap2ixI";

    const closeModal = () => {
      return modalController.dismiss();
    };

    return {
      proto,
      videoId,
      closeModal
    }
  },
}

addIcons({
  check: checkmarkOutline
});
</script>

<style scoped>

#tutorial-text > h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1.4rem;
  margin-bottom: 6px;
} 

#tutorial-text > p {
  margin-bottom: 1.2rem;
  font-size: 1rem;
}
  

</style>