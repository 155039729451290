import { apolloClient } from "../apollo";

// GraphQL queries/mutations
import {
    GET_PARTICIPANT_BY_CODE,
    CREATE_RESPONSE_AS_PARTICIPANT,
    SAVE_RESPONSE_DATA,
    CREATE_PARTICIPANT
  } from "@/graphql/participants";

export default {
    
    createSession({ commit, dispatch }, payload) {
        
        console.log('Creating session...');
        
        commit("clearErrorMessage");
        commit("setPageIsLoading", true);

        return new Promise((resolve, reject) => {
            let participantID;
            let participantResponses = [];
            
            // Check if participant already exists
            dispatch("getParticipantByCode", payload)
            .then((getParticipantResult) => {
                
                console.log('Checking for existing participant...');
                
                if (getParticipantResult.data.allParticipants.length > 0) {
                    
                    console.log('Participant exists!');
                    
                    const participant = getParticipantResult.data.allParticipants[0];
                    participantID = participant.id;
                    participantResponses = participant.responses;
            
                    // Check this is not a duplicate sample, but ignore sample #0 which
                    // is used for testing.
                    if (participantResponses) {
                        
                        const samples = [];
                        participantResponses.forEach(response => {
                            samples.push(response.sample);
                        });
                        
                        if (payload.sample != 0 &&
                            payload.sample != 99 && 
                            samples.includes(payload.sample)) {
                            throw "Error: This sample was already completed.";
                        }
                    }
                    
                    return false; // Don't create a new participant.
                
                } else {
                    console.log('No participant found, creating a new one...');
                    return dispatch("createParticipant", payload)
                }
                
            })
            .then((newParticipantResult) => {  
                
                if (newParticipantResult) {
                    participantID = newParticipantResult.data.createParticipant.id;
                    console.log('Participant ID: ' + participantID);
                }

                console.log("Creating a new response...")
                
                return dispatch("createResponse", { participantID, ...payload });
            })
            .then((response) => { // Create the response row and store the participant data in vuex
                console.log("Storing the participant data...");
                commit("setParticipantID", participantID);
                commit("setSubjectID", payload.subjectID);
                commit("setReferralSource", payload.referralSource);
                commit("setResponseID", response.data.createResponse.id);
                commit("setSampleNum", response.data.createResponse.sample);
                commit("setSampleNumToday", response.data.createResponse.samplesDay);
                commit("setParticipantResponses", participantResponses);
            })
            .catch((e) => {
                commit("setErrorMessage", e);
                reject(e);
            })
            // Stop the loading indicator & resolve the promise
            .finally(() => {
                console.log("End setup.");
                commit("setPageIsLoading", false);
                resolve();
            });
        });
    },

    async getParticipantByCode(_, { subjectID, referralSource }) {
        try {
            
            const participant = await apolloClient
                .query({
                    query: GET_PARTICIPANT_BY_CODE,
                    variables: { subjectID, referralSource, includeResponses: true },
                })
            
            return participant;
        
        } catch(e) {
            console.log(e);
            throw new Error("Could not retrieve participant");
        }
    },
    
    async createParticipant(_, { subjectID, condition, referralSource }) {
        try {
            
            const participantID = await apolloClient.mutate({
                mutation: CREATE_PARTICIPANT,
                variables: { subjectID, condition, referralSource }
            });

            return participantID;
        
        } catch(e) {
            console.log(e);
            throw new Error("Could not create new participant.");
        }
    },

    async createResponse(_, { participantID, day, sample, samplesDay, samplesDone, samplesDoneDay }) {      
        try {
            
            const responseID = await apolloClient
            .mutate({
                mutation: CREATE_RESPONSE_AS_PARTICIPANT,
                variables: { participantID, day, sample, samplesDay, samplesDone, samplesDoneDay }
            });
            
            return responseID;

        } catch(e) {
            console.log(e);
            throw new Error("Could not create new response.");
        }
    },

    async saveResponseItems({ commit, getters }, payload) {
        
        commit("setPageIsLoading", true);
        
        const preparedData = getters.prepareResponseItemData(payload);

        return new Promise((resolve) => {
            apolloClient.mutate({
                mutation: SAVE_RESPONSE_DATA,
                variables: { data: preparedData }
                })
                .then(() => resolve())
                .catch(e => {
                    console.log(e);
                    throw new Error("Error saving response data.");
                })
                .finally(() => commit("setPageIsLoading", false));
        });
    }
}