const acceptability = [
    { 
        description: "Overall, the Three Circles tool was easy to use.", 
        label: "acceptability_easy_to_use" 
    },
    { 
        description: "I found it hard to remember what each of the three circles represented.", 
        label: "acceptability_hard_to_remember" 
    },
    { 
        description: "I would have liked to move the circles closer together or further apart to reflect how I was feeling.", 
        label: "acceptability_wish_could_move_circles" 
    },
    { 
        description: "Rather than resizing circles, I would have preferred a simple rating scale for each one.", 
        subtext: "(e.g., 'Rate how active your red circle (threat system) has been on a scale of 1 to 10')", 
        label: "acceptability_prefer_rating_scale" 
    },
];

export { acceptability }