// Main imports
import { createRouter, createWebHistory } from "@ionic/vue-router"
import store from "@/store"
import { trackRouter } from "vue-gtag-next"

// Utilities
import { get } from "lodash"

// EMA
import Login from "@/views/Login.vue"
import Sleep from "@/views/Sleep.vue"
import Activities from "@/views/Activities.vue"
import ThreeCircles from "@/views/ThreeCircles.vue"
import Emotions1 from "@/views/Emotions-1.vue"
import EverydayLife1 from "@/views/EverydayLife-1.vue"
import EverydayLife2 from "@/views/EverydayLife-2.vue"
import EverydayLife3 from "@/views/EverydayLife-3.vue"

// EMA (v2)
import v2Login from "@/views/v2/Login.vue"
import v2Sleep from "@/views/v2/Sleep.vue"
import v2Activities from "@/views/v2/Activities.vue"
import v2ThreeCircles from "@/views/v2/ThreeCircles.vue"
import v2Emotions1 from "@/views/v2/Emotions-1.vue"
import v2EverydayLife1 from "@/views/v2/EverydayLife-1.vue"
import v2EverydayLife2 from "@/views/v2/EverydayLife-2.vue"
import v2EverydayLife3 from "@/views/v2/EverydayLife-3.vue"
import v2End from "@/views/v2/End.vue"

// UQ student validation routes
import vLogin from "@/views/validation/Login.vue"
import vActivities from "@/views/validation/Activities.vue"
import vThreeCircles from "@/views/validation/ThreeCircles.vue"
import vEmotions from "@/views/validation/Emotions.vue"
import vEverydayLife1 from "@/views/validation/EverydayLife-1.vue"
import vEverydayLife2 from "@/views/validation/EverydayLife-2.vue"

// US student validation routes
import uLogin from "@/views/us-validation/Login.vue"
import uThreeCircles1 from "@/views/us-validation/ThreeCircles-1.vue"
import uThreeCircles2 from "@/views/us-validation/ThreeCircles-2.vue"
import uEmotions from "@/views/us-validation/Emotions.vue"
import uAcceptability from "@/views/us-validation/Acceptability.vue"
import uEnd from "@/views/us-validation/End.vue"

// Misc
import NoAccess from "@/views/NoAccess.vue"
import Demo from "@/views/Demo.vue"
import Thankyou from "@/views/Thankyou"

// Routes object
const routes = [
  
  // EMA routes
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { useProgressBar: false, sequence: "ema" }
  },
  {
    path: "/sleep",
    name: "Sleep",
    component: Sleep,
    meta: { requireLogin: true, sequence: "ema", sampleOnly: 1 }
  },
  {
    path: "/activities",
    name: "Activities",
    component: Activities,
    meta: { requireLogin: true, sequence: "ema" }
  },
  {
    path: "/three-circles",
    name: "ThreeCircles",
    component: ThreeCircles,
    meta: { requireLogin: true, sequence: "ema" }
  },
  {
    path: "/emotions-1",
    name: "Emotions-1",
    component: Emotions1,
    meta: { requireLogin: true, sequence: "ema" }
  },
  {
    path: "/everyday-life-1",
    name: "EverydayLife-1",
    component: EverydayLife1,
    meta: { requireLogin: true, sequence: "ema" }
  },
  {
    path: "/everyday-life-2",
    name: "EverydayLife-2",
    component: EverydayLife2,
    meta: { requireLogin: true, sequence: "ema" }
  },
  {
    path: "/everyday-life-3",
    name: "EverydayLife-3",
    component: EverydayLife3,
    meta: { requireLogin: true, sequence: "ema" }
  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: Thankyou,
    meta: { useProgressBar: false, requireLogin: true, sequence: "ema", redirectUrlBase: "https://exp.psy.uq.edu.au/compassion/done"}
  },
  
  // EMA v2 routes
  {
    path: "/v2/login",
    name: "v2Login",
    component: v2Login,
    meta: { useProgressBar: false, sequence: "ema.v2" }
  },
  {
    path: "/v2/sleep",
    name: "v2Sleep",
    component: v2Sleep,
    meta: { requireLogin: true, sequence: "ema.v2", sampleOnly: 1 }
  },
  {
    path: "/v2/activities",
    name: "v2Activities",
    component: v2Activities,
    meta: { requireLogin: true, sequence: "ema.v2" }
  },
  {
    path: "/v2/three-circles",
    name: "v2ThreeCircles",
    component: v2ThreeCircles,
    meta: { requireLogin: true, sequence: "ema.v2" }
  },
  {
    path: "/v2/emotions-1",
    name: "v2Emotions-1",
    component: v2Emotions1,
    meta: { requireLogin: true, sequence: "ema.v2" }
  },
  {
    path: "/v2/everyday-life-1",
    name: "v2EverydayLife-1",
    component: v2EverydayLife1,
    meta: { requireLogin: true, sequence: "ema.v2" }
  },
  {
    path: "/v2/everyday-life-2",
    name: "v2EverydayLife-2",
    component: v2EverydayLife2,
    meta: { requireLogin: true, sequence: "ema.v2" }
  },
  {
    path: "/v2/everyday-life-3",
    name: "v2EverydayLife-3",
    component: v2EverydayLife3,
    meta: { requireLogin: true, sequence: "ema.v2" }
  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: Thankyou,
    meta: { useProgressBar: false, requireLogin: true, sequence: "ema.v2", redirectUrlBase: "/v2/end"}
  },
  {
    path: "/v2/end",
    name: "v2End",
    component: v2End,
    meta: { useProgressBar: false },
  },
    
  // SONA (UQ student) validation routes
  {
    path: "/v/login",
    name: "vLogin",
    component: vLogin,
    meta: { useProgressBar: false, sequence: "sona" },
  },
  {
    path: "/v/activities",
    name: "vActivities",
    component: vActivities,
    meta: { requireLogin: true, sequence: "sona" },
  },
  {
    path: "/v/threecircles",
    name: "vThreeCircles",
    component: vThreeCircles,
    meta: { requireLogin: true, sequence: "sona" },
  },
  {
    path: "/v/emotions",
    name: "vEmotions",
    component: vEmotions,
    meta: { requireLogin: true, sequence: "sona" },
  },
  {
    path: "/v/everyday-life-1",
    name: "vEverydayLife1",
    component: vEverydayLife1,
    meta: { requireLogin: true, sequence: "sona" },
  },
  {
    path: "/v/everyday-life-2",
    name: "vEverydayLife2",
    component: vEverydayLife2,
    meta: { requireLogin: true, sequence: "sona" },
  },
  {
    path: "/v/thankyou",
    name: "vThankyou",
    component: Thankyou,
    meta: {useProgressBar: false, requireLogin: true, sequence: "sona", redirectUrlBase: "https://exp.psy.uq.edu.au/onlinecompassion/done"}
  },
  
  // Prolific validation
  // US student validation
  // Dylan young adult validation
  // Dylan teen validation
  // Note: Redirect link at end is different for each
  {
    path: "/us/login",
    name: "uLogin",
    component: uLogin,
    meta: { useProgressBar: false, sequence: "us" },
  },
  {
    path: "/us/threecircles-page-1",
    name: "uThreeCircles1",
    component: uThreeCircles1,
    meta: { requireLogin: true, sequence: "us" }
  },
  {
    path: "/us/threecircles-page-2",
    name: "uThreeCircles2",
    component: uThreeCircles2,
    meta: { requireLogin: true, sequence: "us" }
  },
  {
    path: "/us/emotions",
    name: "uEmotions",
    component: uEmotions,
    meta: { requireLogin: true, sequence: "us" }
  },
  {
    path: "/us/acceptability",
    name: "uAcceptability",
    component: uAcceptability,
    meta: { requireLogin: true, sequence: "us" }
  },
  {
    path: "/us/thankyou",
    name: "uThankyou",
    component: Thankyou,
    meta: { requireLogin: true, sequence: "us", redirectUrlBase: "/us/end" }
  },
  {
    path: "/us/end",
    name: "uEnd",
    component: uEnd,
    meta: { useProgressBar: false },
  },
  
  // Demo page
  {
    path: "/demo",
    name: "Demo",
    component: Demo,
    meta: { useProgressBar: false },
  },
  
  // Error page
  {
    path: "/no-access",
    name: "NoAccess",
    component: NoAccess,
    meta: { useProgressBar: false },
  },
  
  // Catch-all: redirect to no-access page
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    redirect: "/no-access",
    meta: { useProgressBar: false },
  },

];

// Setup
const router = createRouter({
  history: createWebHistory(),
  routes,
  swipeBackEnabled: false
});

trackRouter(router, { useScreenview: true });

/* Navigation guards: Make sure user hits the right path */
router.beforeEach((to, from, next) => {
  const participantID = store.state.participantID;
  const pageIsSaved = store.getters.pageIsSaved(to.name);
  store.commit("clearErrorMessage");

  if (pageIsSaved) {
    store.commit("setHasModal", to.name);
    next();
  } else {
    
    // Redirect to error page (unless one of the non-protected routes) if participantID not set
    if (get(to, ["meta", "requireLogin"], false) && !participantID) {
      if (process.env.VUE_APP_NODE_ENV === "production") {
        console.log('REDIRECTING')
        next({ name: 'NoAccess' });
      } else {
        // Mock some IDs for development and continue
        store.commit("setParticipantID", "603d87fc73d78df7d234f824");
        store.commit("setResponseID", "603d8ca45d870ef974b0164a");
        store.commit("setSampleNumToday", 3);
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
