<template>
    <ion-list>
        <ion-list-header :mode="mode">
        <ion-label>{{ data.description }}</ion-label>
        </ion-list-header>
        <ion-item v-for="activity in activities || []" :key="activity.textVal">
        <ion-icon :name="activity.icon" class="ion-padding-end"></ion-icon>
        <ion-label>{{ activity.description }}</ion-label>
        <ion-checkbox 
            slot="start"
            @update:modelValue="activity.isChecked = $event"
            :modelValue="activity.isChecked">
        </ion-checkbox>
        </ion-item>
    </ion-list>
</template>

<script>
import { addIcons } from 'ionicons'
import { arrowForward, balloon, bed, business, cafe, cut, fileTrayFull, happy, heart, library, tv, square, desktop, bus, walk, barbell, headset, pizza, rose, fish, newspaper, chatbubbles, bag, sparkles } from 'ionicons/icons'
import { IonIcon, IonList, IonItem, IonCheckbox, IonLabel, IonListHeader } from '@ionic/vue'
import { defineComponent, reactive, computed, inject, watch } from 'vue'
import useItemResponder from '@/modules/useItemResponder'
import activityList from "@/data/activities.js"

export default defineComponent({
  name: 'activities-page',
  components: {
    IonIcon,
    IonList, 
    IonItem, 
    IonCheckbox, 
    IonLabel,
    IonListHeader,
  },
  props: ['itemNum'],
  emits: ['itemsUpdated'],
  setup(props, context) {
    const mode = inject("mode")
    const activities = reactive(activityList)
    activities.map((a) => {
      a.isChecked = false
    })

    const getTextValFromSelectedItems = computed(
      () => activities
            .filter(a => a.isChecked)
            .map(a => a.textVal)
            .join('|')
    )

    const data = reactive({
        itemNum: props.itemNum,
        label: "activities",
        textVal: getTextValFromSelectedItems,
        description: "What are you doing right now?"
    })

    watch(
        () => data,
        (data) => context.emit('itemsUpdated', data),
        { deep: true, immediate: true }
    )
    
    useItemResponder(activities, 'checkbox')

    return {
      mode,
      data,
      activities,
    }
  },
});

addIcons({
  'arrow-forward': arrowForward,
  balloon,
  bed,
  business,
  cafe,
  cut,
  'file-tray-full': fileTrayFull,
  happy,
  heart,
  library,
  square,
  sparkles,
  tv,
  desktop,
  bus,
  walk,
  barbell,
  headset,
  pizza,
  rose,
  fish,
  newspaper,
  chatbubbles,
  bag
})
</script>