<template>
    <ion-page>
        <ion-header :translucent="true" :mode="mode">
            <slot name="toolbar">
                <ion-toolbar>
                    <ion-buttons slot="start" v-if="showBackButton">
                        <ion-back-button></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ title }}</ion-title>
                </ion-toolbar>
            </slot>
        </ion-header>

        <ion-content :class="[slot !== 'fixed' ? 'ion-padding' : 'relative']" :fullscreen="fullscreen" :slot="slot">
            <slot></slot>
        </ion-content>

        <ion-footer>
            <ion-toolbar>
                <ion-text v-if="errorMessage" color="danger" class="error">{{ errorMessage }}</ion-text>
                <ion-buttons slot="end">
                    <ion-button :disabled="pageIsLoading || pageIsIncomplete" fill="outline" color="primary" @click="saveAndContinue">
                        <ion-text>Continue</ion-text>
                        <ion-spinner slot="end" name="dots" v-if="pageIsLoading"></ion-spinner>
                        <ion-icon slot="end" name="arrow-forward" v-else></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>
    </ion-page>
</template>

<script>
import { useStore } from 'vuex';
import { IonPage, IonContent, IonHeader, IonTitle, IonToolbar, IonIcon, IonButton, IonButtons, IonFooter, IonSpinner, IonText, IonBackButton } from '@ionic/vue';
import { defineComponent, inject, toRef, computed } from 'vue';
import { addIcons } from 'ionicons';
import { arrowForward } from 'ionicons/icons';
import usePageManager from '@/modules/usePageManager';

export default defineComponent({
    components: {
        IonContent, 
        IonHeader,
        IonPage, 
        IonTitle, 
        IonToolbar, 
        IonIcon, 
        IonButtons, 
        IonButton,
        IonBackButton, 
        IonFooter,
        IonSpinner,
        IonText
    },
    props: {
        title: {
            type: String,
            required: true
        },
        data: {
            type: Object,
        },
        showBackButton: {
            type: Boolean,
            default: false
        },
        fullscreen: {
            type: Boolean,
            default: true
        },
        slot: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const store = useStore();
        const data = toRef(props, "data")
        const mode = inject("mode")
        const { saveAndContinue } = usePageManager(data)

        return {
            pageIsLoading: computed(() => store.state.pageIsLoading),
            pageIsIncomplete: computed(() => store.state.pageIsIncomplete),
            errorMessage: computed(() => store.state.errorMessage),
            saveAndContinue,
            mode
        }
    }
});

addIcons({
    "arrow-forward": arrowForward
});
</script>

<style src="@/assets/css/layoutstyles.css">
</style>