<template>
  <main-layout 
    title="Current activities" 
    :data="activityData"
  >
    <activities-item-view :itemNum="ITEM_NUM" @itemsUpdated="updateSelectedItems"></activities-item-view>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayoutV2.vue'
import { defineComponent, ref, onMounted } from 'vue'
import ActivitiesItemView from '@/components/ActivitiesItemView.vue'

const ITEM_NUM = 2;

export default defineComponent({
  name: 'activities-page',
  components: {
    MainLayout,
    ActivitiesItemView,
  },
  setup() {
    const activityData = ref(null)
    const updateSelectedItems = (data) => activityData.value = data

    onMounted(() => console.log("Activities loaded"))

    return {
      activityData,
      updateSelectedItems,
      ITEM_NUM,
    }
  },
})
</script>