<template>
    <ion-page>
        <ion-content fullscreen class="ion-padding-horizontal">
            <div id="wrapper">
                <div id="wrapper-inner">
                    <h2 class="ion-text-center ion-margin-bottom">No access</h2>
                    <p class="ion-text-center">Sorry, this link was incorrect or has expired.</p>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {IonPage, IonContent} from '@ionic/vue';

export default {
    name: "no-access",
    components: {
        IonPage, 
        IonContent
    }
}
</script>

<style scoped>
#wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 1;
    align-items: center;
}
</style>