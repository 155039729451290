const everydaylife1 = [
    // 0
    {
        description: "In the past 30 minutes, did you have an opportunity to be compassionate towards somebody?",
        label: "compassion_opportunity_other",
        numericVal: null,
        isRequired: true,
        type: "radio",
        options: [
            {
                description: "Yes",
                val: 1,
            },
            {
                description: "No",
                val: 0,
            },
        ]
    },
    // If no...
    {
        description: "Why didn't you have the opportunity to be compassionate towards somebody? (Choose all that apply)",
        label: "why_no_compassion_opportunity_other",
        numericVal: null,
        isRequired: true,
        type: "checkbox",
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 0
        },
        options: [
            { 
                description: "I didn't see or interact with anybody",
                label: "no_see_interact",
                isChecked: false,
            },
            { 
                description: "I didn’t see anybody that needed compassion", 
                label: "no_see_anyone",
                isChecked: false
            },
            { 
                description: "I was busy doing something else", 
                label: "busy",
                isChecked: false
            },
            { 
                description: "I didn't want to feel pressure to act a certain way (e.g., give money)", 
                label: "avoid_pressure",
                isChecked: false
            },
            { 
                description: "I didn't care about the person", 
                label: "no_care",
                isChecked: false
            },
            { 
                description: "It was too hard", 
                label: "too_hard",
                isChecked: false
            },
            { 
                description: "It was emotionally painful", 
                label: "emotionally_painful",
                isChecked: false
            },
            { 
                description: "I didn't have time", 
                label: "no_time",
                isChecked: false
            },
            { 
                description: "Other", 
                label: "other",
                isChecked: false,
            },
        ]
    },
    {
        description: "For what other reason did you lack an opportunity to be compassionate towards somebody?",
        label: "why_no_compassion_opportunity_other_other_reason",
        textVal: null,
        type: "textarea",
        dependsOn: {
            label: "why_no_compassion_opportunity_other",
            val: "other"
        },
    },
    {
        description: "Did you lack energy to be compassionate to others?",
        label: "lack_energy_compassion_other",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "Did you lack motivation to be compassionate to others?",
        label: "lack_motivation_compassion_other",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "Did you try and avoid noticing moments to be compassionate?",
        label: "avoid_noticing_compassion_others",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "Did you feel like you lacked the skills to be compassionate to others?",
        label: "lack_skills_compassion_others",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    // If yes...
    {
        description: "Did you do something to help the person suffering (i.e., take some kind of action)?",
        label: "do_something_other",
        numericVal: null,
        isRequired: true,
        type: "radio",
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 1
        },
        options: [
            {
                description: "Yes",
                val: 1,
            },
            {
                description: "No",
                val: 0,
            },
        ]
    },
    // 2
    {
        description: "Explain the situation in one sentence",
        label: "explain_situation_compassion_other",
        textVal: null,
        type: "textarea",
        dependsOn: {
            label: "do_something_other",
            val: 1
        },
        sampleOnly: 3
    },
    {
        description: "In relation to this compassionate opportunity, what emotions did you feel?",
        label: "compassion_emotions_other",
        textVal: null,
        type: "checkbox",
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 1
        },
        options: [
            { 
                description: "Happiness", 
                label: "happiness",
                isChecked: false
            },
            { 
                description: "Anger", 
                label: "anger",
                isChecked: false,
            },
            { 
                description: "Anxiety", 
                label: "anxiety",
                isChecked: false,
            },
            { 
                description: "Stress", 
                label: "stress",
                isChecked: false,
            },
            { 
                description: "Embarrassment", 
                label: "embarrassed",
                isChecked: false,
            },
            { 
                description: "Humour", 
                label: "humour",
                isChecked: false,
            },
            { 
                description: "Fear", 
                label: "fear",
                isChecked: false,
            },
            { 
                description: "Disgust", 
                label: "disgust",
                isChecked: false,
            },
            { 
                description: "Sadness", 
                label: "sad",
                isChecked: false,
            },
            { 
                description: "Contempt", 
                label: "contempt",
                isChecked: false,
            },
            { 
                description: "Surprise", 
                label: "surprise",
                isChecked: false,
            },
            { 
                description: "Pain", 
                label: "pain",
                isChecked: false,
            },
        ]
    },
    // 5
    {
        description: "In relation to this compassionate opportunity, was the emotional experience you felt positive or negative?",
        label: "emotional_experience_other",
        numericVal: null,
        type: "radio",
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 1
        },
        options: [
            { 
                description: "Extremely negative", 
                val: 1,
            },
            { 
                description: "Negative", 
                val: 2,
            },
            { 
                description: "More negative than positive", 
                val: 3,
            },
            { 
                description: "Mixed", 
                val: 4,
            },
            { 
                description: "More positive than negative", 
                val: 5,
            },
            { 
                description: "Positive", 
                val: 6,
            },
            { 
                description: "Extremely positive", 
                val: 7,
            },
        ]
    },
    // 6
    {
        description: "How close are you with the person involved in this compassionate opportunity?",
        label: "how_close_other",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 1
        },
        options: ["Total stranger", "Very close relationship"]
    },
    // 7
    {
        description: "How much do you like the person involved in this compassionate opportunity?",
        label: "how_much_like_other",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 1
        },
        options: ["Not at all", "Very much"]
    },
    // 8
    {
        description: "In relation to this compassionate opportunity, to what extent did you feel personal distress?",
        label: "personal_distress_other",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_other",
            val: 1
        },
        options: ["Not at all distressed", "Extremely distressed"]
    },
    // If yes (opportunity) but no (do something)
    {
        description: "Why weren’t you compassionate (choose all that apply)?",
        label: "why_no_compassion_other",
        textVal: null,
        type: "checkbox",
        isRequired: true,
        dependsOn: {
            label: "do_something_other",
            val: 0
        },
        options: [
            { 
                description: "I was busy doing something else", 
                label: "busy",
                isChecked: false
            },
            { 
                description: "I didn't want to feel pressure to act a certain way (e.g., give money)", 
                label: "pressure",
                isChecked: false,
            },
            { 
                description: "I didn't care about the person", 
                label: "no_care",
                isChecked: false,
            },
            { 
                description: "It was too hard", 
                label: "hard",
                isChecked: false,
            },
            { 
                description: "It was emotionally painful", 
                label: "emotionally_painful",
                isChecked: false,
            },
            { 
                description: "I didn't have time", 
                label: "no_time",
                isChecked: false,
            },
            { 
                description: "Other", 
                label: "other",
                isChecked: false,
            },
        ]
    },
    {
        description: "For what other reason were you not compassionate?",
        label: "why_no_compassion_other_other_reason",
        textVal: null,
        type: "textarea",
        dependsOn: {
            label: "why_no_compassion_other",
            val: "other"
        },
    },
    {
        description: "Was your compassionate action considered helpful?",
        label: "was_helpful_other",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "do_something_other",
            val: 1
        },
        options: ["Not at all", "Very much"]
    },
    // 13
    {
        description: "How difficult was it to be compassionate?",
        label: "how_difficult_other",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "do_something_other",
            val: 1
        },
        options: ["Not at all difficult", "Extremely difficult"]
    },
    // New items
    {
        description: "How meaningful was it for you to be compassionate?",
        label: "how_meaningful_other",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "do_something_other",
            val: 1
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "How important was it for you to be compassionate?",
        label: "how_important_other",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "do_something_other",
            val: 1
        },
        options: ["Not at all", "Very much"]
    },
]

const everydaylife2 = [
    {
        description: "In the past 30 minutes, did you have an opportunity to be compassionate to yourself?",
        label: "compassion_opportunity_self",
        numericVal: null,
        isRequired: true,
        type: "radio",
        options: [
            {
                description: "Yes",
                val: 1,
            },
            {
                description: "No",
                val: 0,
            },
        ]
    },
    // If no...
    {
        description: "Why didn't you have the opportunity to be compassionate to yourself? (Choose all that apply)",
        label: "why_no_compassion_opportunity_self",
        numericVal: null,
        isRequired: true,
        type: "checkbox",
        dependsOn: {
            label: "compassion_opportunity_self",
            val: 0
        },
        options: [
            { 
                description: "I didn't need compassion", 
                label: "no_need",
                isChecked: false
            },
            { 
                description: "I didn't have the time", 
                label: "no_time",
                isChecked: false
            },
            { 
                description: "It was too hard", 
                label: "too_hard",
                isChecked: false
            },
            { 
                description: "It was emotionally painful", 
                label: "emotionally_painful",
                isChecked: false
            },
            { 
                description: "It feels weak", 
                label: "feels_weak",
                isChecked: false
            },
            { 
                description: "I didn't think it would help", 
                label: "not_help",
                isChecked: false
            },
            { 
                description: "I don't deserve it", 
                label: "not_deserve",
                isChecked: false
            },
            { 
                description: "It's selfish", 
                label: "is_selfish",
                isChecked: false
            },
            { 
                description: "I wouldn't know how to", 
                label: "not_know_how",
                isChecked: false
            },
            { 
                description: "Other", 
                label: "other",
                isChecked: false,
            },
        ]
    },
    {
        description: "For what other reason did you lack an opportunity to be compassionate towards yourself?",
        label: "why_no_compassion_opportunity_self_other_reason",
        textVal: null,
        type: "textarea",
        dependsOn: {
            label: "why_no_compassion_opportunity_self",
            val: "other"
        },
    },
    {
        description: "Did you lack energy to be self-compassionate?",
        label: "lack_energy_compassion_self",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_self",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "Did you lack motivation to be self-compassionate?",
        label: "lack_motivation_compassion_self",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_self",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "Did you try and avoid noticing moments to be self-compassionate?",
        label: "avoid_noticing_compassion_self",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_self",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "Did you feel like you lacked the skills to be self-compassionate?",
        label: "lack_skills_compassion_self",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_self",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    // If yes...
    {
        description: "Did you act in a compassionate way towards yourself when this opportunity arose?",
        label: "act_compassion_self",
        numericVal: null,
        isRequired: true,
        type: "radio",
        options: [
            {
                description: "Yes",
                val: 1,
            },
            {
                description: "No",
                val: 0,
            },
        ],
        dependsOn: {
            label: "compassion_opportunity_self",
            val: 1
        },
    },
    {
        description: "Explain the situation in one sentence",
        label: "situation_compassion_self",
        textVal: null,
        type: "textarea",
        dependsOn: {
            label: "compassion_opportunity_self",
            val: 1
        },
        sampleOnly: 3
    },
    {
        description: "In relation to the opportunity to be compassionate to yourself, what emotions did you feel?",
        label: "compassion_emotions_self",
        textVal: null,
        type: "checkbox",
        isRequired: true,
        options: [
            { 
                description: "Happiness", 
                label: "happiness",
                isChecked: false
            },
            { 
                description: "Anger", 
                label: "anger",
                isChecked: false,
            },
            { 
                description: "Anxiety", 
                label: "anxiety",
                isChecked: false,
            },
            { 
                description: "Stress", 
                label: "stress",
                isChecked: false,
            },
            { 
                description: "Embarrassment", 
                label: "embarrassed",
                isChecked: false,
            },
            { 
                description: "Humour", 
                label: "humour",
                isChecked: false,
            },
            { 
                description: "Fear", 
                label: "fear",
                isChecked: false,
            },
            { 
                description: "Disgust", 
                label: "disgust",
                isChecked: false,
            },
            { 
                description: "Sadness", 
                label: "sad",
                isChecked: false,
            },
            { 
                description: "Contempt", 
                label: "contempt",
                isChecked: false,
            },
            { 
                description: "Surprise", 
                label: "surprise",
                isChecked: false,
            },
            { 
                description: "Pain", 
                label: "pain",
                isChecked: false,
            },
        ],
        dependsOn: {
            label: "compassion_opportunity_self",
            val: 1
        },
    },
    {
        description: "In relation to being compassionate to yourself, was the emotional experience you felt positive or negative?",
        label: "emotional_experience_self",
        numericVal: null,
        type: "radio",
        isRequired: true,
        options: [
            { 
                description: "Extremely negative", 
                val: 1,
            },
            { 
                description: "Negative", 
                val: 2,
            },
            { 
                description: "More negative than positive", 
                val: 3,
            },
            { 
                description: "Mixed", 
                val: 4,
            },
            { 
                description: "More positive than negative", 
                val: 5,
            },
            { 
                description: "Positive", 
                val: 6,
            },
            { 
                description: "Extremely positive", 
                val: 7,
            },
        ],
        dependsOn: {
            label: "compassion_opportunity_self",
            val: 1
        },
    },
    {
        description: "In relation to being compassionate to yourself, to what extent did you feel personal distress?",
        label: "personal_distress_self",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        options: ["Not at all distressed", "Extremely distressed"],
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_self",
            val: 1
        },
    },
    // If no act...
    {
        description: "Why weren’t you compassionate to yourself (choose all that apply)?",
        label: "why_no_compassion_self",
        textVal: null,
        type: "checkbox",
        isRequired: true,
        options: [
            { 
                description: "I didn't have the time", 
                label: "no_time",
                isChecked: false
            },
            { 
                description: "It was too hard", 
                label: "too_hard",
                isChecked: false,
            },
            { 
                description: "It was emotionally painful", 
                label: "emotional_pain",
                isChecked: false,
            },
            { 
                description: "It feels weak", 
                label: "feels_weak",
                isChecked: false,
            },
            { 
                description: "I didn't think it would help", 
                label: "not_help",
                isChecked: false,
            },
            { 
                description: "I don't deserve it", 
                label: "no_deserve",
                isChecked: false,
            },
            { 
                description: "It's selfish", 
                label: "selfish",
                isChecked: false,
            },
            { 
                description: "I wouldn't know how to", 
                label: "not_know_how",
                isChecked: false,
            },
            { 
                description: "Other", 
                label: "other",
                isChecked: false,
            },
        ],
        dependsOn: {
            label: "act_compassion_self",
            val: 0
        },
    },
    {
        description: "For what other reason were you not compassionate to yourself?",
        label: "no_compassion_self_other_reason",
        textVal: null,
        type: "textarea",
        dependsOn: {
            label: "why_no_compassion_self",
            val: "other"
        },
    },
    // If yes act...
    {
        description: "Did you experience your compassion as helpful?",
        label: "was_helpful_self",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        options: ["Not at all", "Very much"],
        isRequired: true,
        dependsOn: {
            label: "act_compassion_self",
            val: 1
        },
    },
    // 8
    {
        description: "How difficult was it to be compassionate to yourself?",
        label: "how_difficult_self",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        options: ["Not at all difficult", "Extremely difficult"],
        isRequired: true,
        dependsOn: {
            label: "act_compassion_self",
            val: 1
        }
    },
    // New items
    {
        description: "How meaningful was it for you to be self-compassionate?",
        label: "how_meaningful_self",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "act_compassion_self",
            val: 1
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "How important was it for you to be self-compassionate?",
        label: "how_important_self",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "act_compassion_self",
            val: 1
        },
        options: ["Not at all", "Very much"]
    },
]

const everydaylife3 = [
    {
        description: "In the past 30 minutes, did you have an opportunity to receive compassion from someone?",
        label: "compassion_opportunity_receive",
        numericVal: null,
        isRequired: true,
        type: "radio",
        options: [
            {
                description: "Yes",
                val: 1,
            },
            {
                description: "No",
                val: 0,
            },
        ]
    },
    // If no...
    {
        description: "Why didn't you have the opportunity to receive compassion from somebody? (Choose all that apply)",
        label: "why_no_compassion_opportunity_receive",
        numericVal: null,
        isRequired: true,
        type: "checkbox",
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 0
        },
        options: [
            { 
                description: "I didn't need compassion", 
                label: "no_need",
                isChecked: false
            },
            { 
                description: "I didn't see anyone", 
                label: "no_see_anyone",
                isChecked: false
            },
            { 
                description: "I was busy", 
                label: "too_busy",
                isChecked: false
            },
            { 
                description: "I didn't want to feel pressure to act a certain way (e.g., say thank you)", 
                label: "avoid_pressure",
                isChecked: false
            },
            { 
                description: "I didn't care about the person", 
                label: "no_care",
                isChecked: false
            },
            { 
                description: "It was too hard", 
                label: "too_hard",
                isChecked: false
            },
            { 
                description: "It was emotionally painful", 
                label: "emotionally_painful",
                isChecked: false
            },
            { 
                description: "I didn't have time", 
                label: "no_time",
                isChecked: false
            },
            { 
                description: "It feels weak", 
                label: "feels_weak",
                isChecked: false
            },
            { 
                description: "I didn't think it would help", 
                label: "not_help",
                isChecked: false
            },
            { 
                description: "I don't deserve it", 
                label: "not_deserve",
                isChecked: false
            },
            { 
                description: "It's selfish", 
                label: "is_selfish",
                isChecked: false
            },
            { 
                description: "I wouldn't know how to let them", 
                label: "not_know_how",
                isChecked: false
            },
            { 
                description: "Other", 
                label: "other",
                isChecked: false,
            },
        ]
    },
    {
        description: "For what other reason did you lack an opportunity to receive compassion from someone else?",
        label: "why_no_compassion_opportunity_receive_other_reason",
        textVal: null,
        type: "textarea",
        dependsOn: {
            label: "why_no_compassion_opportunity_receive",
            val: "other"
        },
    },
    {
        description: "Did you lack energy to receive compassion from others?",
        label: "lack_energy_compassion_receive",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "Did you lack motivation to receive compassion from others?",
        label: "lack_motivation_compassion_receive",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "Did you try and avoid noticing moments to receive compassion from others?",
        label: "avoid_noticing_compassion_receive",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    {
        description: "Did you feel like you lacked the skills to be able to receive compassion from others?",
        label: "lack_skills_compassion_receive",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 0
        },
        options: ["Not at all", "Very much"]
    },
    // If yes...
    {
        description: "Did you allow that person to act compassionately towards you?",
        label: "receive_compassion_from",
        numericVal: null,
        isRequired: true,
        type: "radio",
        options: [
            {
                description: "Yes",
                val: 1,
            },
            {
                description: "No",
                val: 0,
            },
        ],
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 1
        },
    },
    {
        description: "In relation to this opportunity to receive compassion, how close are you with the person or people involved?",
        label: "how_close_receive",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        options: ["Total stranger", "Very close relationship"],
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 1
        },
    },
    // If no receive...
    {
        description: "Why didn’t you let the other person or people be compassionate to you (choose all that apply)?",
        label: "why_no_compassion_receive_from",
        textVal: null,
        type: "checkbox",
        isRequired: true,
        options: [
            { 
                description: "I was busy", 
                label: "busy",
                isChecked: false
            },
            { 
                description: "I didn't want to feel pressure to act a certain way (e.g., say thank you)", 
                label: "pressure",
                isChecked: false,
            },
            { 
                description: "I didn't care about the person", 
                label: "no_care",
                isChecked: false,
            },
            { 
                description: "It was too hard", 
                label: "hard",
                isChecked: false,
            },
            { 
                description: "It was emotionally painful", 
                label: "emotionally_painful",
                isChecked: false,
            },
            { 
                description: "I didn't have time", 
                label: "no_time",
                isChecked: false,
            },
            { 
                description: "It feels weak", 
                label: "feels_weak",
                isChecked: false,
            },
            { 
                description: "I didn't think it would help", 
                label: "not_help",
                isChecked: false,
            },
            { 
                description: "I don't deserve it", 
                label: "no_deserve",
                isChecked: false,
            },
            { 
                description: "It's selfish", 
                label: "selfish",
                isChecked: false,
            },
            { 
                description: "I wouldn't know how to let them", 
                label: "not_know_how",
                isChecked: false,
            },
            { 
                description: "Other", 
                label: "other",
                isChecked: false,
            },
        ],
        dependsOn: {
            label: "receive_compassion_from",
            val: 0
        },
    },
    {
        description: "For what other reason did you not let them be compassionate to you?",
        label: "why_no_compassion_receive_from_other_reason",
        textVal: null,
        type: "textarea",
        dependsOn: {
            label: "why_no_compassion_receive_from",
            val: "other"
        },
    },
    // If yes receive...
    {
        description: "How much do you like the person you received compassion from?",
        label: "how_much_like_person_receive",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        options: ["Not at all", "Very much"],
        isRequired: true,
        dependsOn: {
            label: "receive_compassion_from",
            val: 1
        },
    },
    {
        description: "Did you experience their compassion as helpful?",
        label: "was_helpful_receive",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        options: ["Not at all", "Very much"],
        isRequired: true,
        dependsOn: {
            label: "receive_compassion_from",
            val: 1
        },
    },
    {
        description: "How difficult was it to receive this compassionate action from this person/people?",
        label: "how_difficult_receive",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        options: ["Not at all difficult", "Extremely difficult"],
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 1
        }
    },
    {
        description: "In relation to receiving compassion, what emotions did you feel?",
        label: "compassion_emotions_receive",
        textVal: null,
        type: "checkbox",
        isRequired: true,
        options: [
            { 
                description: "Happiness", 
                label: "happiness",
                isChecked: false
            },
            { 
                description: "Anger", 
                label: "anger",
                isChecked: false,
            },
            { 
                description: "Anxiety", 
                label: "anxiety",
                isChecked: false,
            },
            { 
                description: "Stress", 
                label: "stress",
                isChecked: false,
            },
            { 
                description: "Embarrassment", 
                label: "embarrassed",
                isChecked: false,
            },
            { 
                description: "Humour", 
                label: "humour",
                isChecked: false,
            },
            { 
                description: "Fear", 
                label: "fear",
                isChecked: false,
            },
            { 
                description: "Disgust", 
                label: "disgust",
                isChecked: false,
            },
            { 
                description: "Sadness", 
                label: "sad",
                isChecked: false,
            },
            { 
                description: "Contempt", 
                label: "contempt",
                isChecked: false,
            },
            { 
                description: "Surprise", 
                label: "surprise",
                isChecked: false,
            },
            { 
                description: "Pain", 
                label: "pain",
                isChecked: false,
            },
            { 
                description: "Gratitude", 
                label: "gratitude",
                isChecked: false,
            },
        ],
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 1
        }
    },
    {
        description: "In relation to receiving compassion, to what extent did you feel personal distress?",
        label: "personal_distress_receive",
        numericVal: null,
        type: "slider",
        min: 1,
        max: 7,
        isRequired: true,
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 1
        },
        options: ["Not at all distressed", "Extremely distressed"]
    },
    {
        description: "In relation to receiving compassion, was the emotional experience you felt positive or negative?",
        label: "emotional_experience_receive",
        numericVal: null,
        type: "radio",
        isRequired: true,
        options: [
            { 
                description: "Extremely negative", 
                val: 1,
            },
            { 
                description: "Negative", 
                val: 2,
            },
            { 
                description: "More negative than positive", 
                val: 3,
            },
            { 
                description: "Mixed", 
                val: 4,
            },
            { 
                description: "More positive than negative", 
                val: 5,
            },
            { 
                description: "Positive", 
                val: 6,
            },
            { 
                description: "Extremely positive", 
                val: 7,
            },
        ],
        dependsOn: {
            label: "compassion_opportunity_receive",
            val: 1
        }
    },
    {
        description: "Have you done a compassion meditation today?",
        label: "done_meditation",
        numericVal: null,
        isRequired: true,
        type: "radio",
        options: [
            {
                description: "Yes",
                val: 1,
            },
            {
                description: "No",
                val: 0,
            },
        ],
    }
    
]

const startingNum = 9;
everydaylife1.map((item, index) => {
    if (!Object.keys(item).includes("isRequired")) item.isRequired = false;
    if (!Object.keys(item).includes("type")) item.type = null;
    if (!Object.keys(item).includes("itemNum")) item.itemNum = index + startingNum;
})

everydaylife2.map((item, index) => {
    if (!Object.keys(item).includes("isRequired")) item.isRequired = false;
    if (!Object.keys(item).includes("type")) item.type = null;
    if (!Object.keys(item).includes("itemNum")) item.itemNum = index + everydaylife1.length;
})

everydaylife3.map((item, index) => {
    if (!Object.keys(item).includes("isRequired")) item.isRequired = false;
    if (!Object.keys(item).includes("type")) item.type = null;
    if (!Object.keys(item).includes("itemNum")) item.itemNum = index + everydaylife2.length;
})

const spares = [
    {
        itemNum: 12,
        description: "How long ago would you estimate this compassionate opportunity occurred?",
        label: "how_long_ago",
        textVal: null,
        options: [
            { 
                description: "Less than a minute age", 
                label: "<1min",
                isChecked: false
            },
            { 
                description: "1 minute ago", 
                label: "1min",
                isChecked: false,
            },
            { 
                description: "2 minutes ago", 
                label: "2min",
                isChecked: false,
            },
            { 
                description: "3 minutes ago", 
                label: "3min",
                isChecked: false,
            },
        ]

    }
]

export { everydaylife1, everydaylife2, everydaylife3, spares };