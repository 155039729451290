<template>
  <ion-page>
    <ion-header translucent="true" mode="ios">
      <ion-toolbar>
        <ion-title>Three Circles</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primary" fill="clear" shape="round" size="large" @click="showTutorial">
            <ion-icon slot="start" name="school-outline"></ion-icon>Help
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div v-if="labels.length === 0" id="canvas-wrapper-demo" class="canvas-wrapper">
      <circle-canvas @updated="updateData" v-if="isLoaded" :width="width" :height="height"
        canvasId="canvas-1"></circle-canvas>
      <div class="circle-info-wrapper">
        <div class="circle-info">
          <div class="blue-circle">Blue = drive</div>
          <div class="red-circle">Red = threat</div>
          <div class="green-circle">Green = soothing</div>
        </div>
      </div>
    </div>
    <div v-for="label in labels" :key="label" class="list">
      {{ label }}
    </div>
  </ion-page>
</template>

<script>
import { onMounted, ref } from 'vue'
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButton, IonButtons, IonIcon } from '@ionic/vue'
import { addIcons } from 'ionicons'
import { schoolOutline } from 'ionicons/icons'
import { useCircleLoader } from "@/modules/useCircleLoader"
import { useTutorial } from "@/modules/useTutorial"
import Modal from '@/views/Tutorial.vue'
import CircleCanvas from '@/components/CircleCanvas.vue'
import { everydaylife1 as everydaylife1a, everydaylife2 as everydaylife2a, everydaylife3 as everydaylife3a } from '@/data/everydaylife'
import { everydaylife1 as everydaylife1b, everydaylife2 as everydaylife2b, everydaylife3 as everydaylife3b } from '@/data/v2/everydaylife'

export default {
  name: "three-circles",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    IonIcon,
    CircleCanvas,
  },
  setup() {
    const { isLoaded, updateData, width, height, circleData } = useCircleLoader('canvas-wrapper-demo', 4, 'test')
    const { showTutorial } = useTutorial(Modal)
    const labels = ref([])
    const print = false

    const printLabels = () => {

      let everydaylife1 = []
      let everydaylife2 = []
      let everydaylife3 = []

      everydaylife1b.map(item => {
        everydaylife1.push(item.label)
      })
      everydaylife1a.map(item => {
        everydaylife1.push(item.label)
      })
      everydaylife1 = [...new Set(everydaylife1)]


      everydaylife2b.map(item => {
        everydaylife2.push(item.label)
      })
      everydaylife2a.map(item => {
        everydaylife2.push(item.label)
      })
      everydaylife2 = [...new Set(everydaylife2)]


      everydaylife3b.map(item => {
        everydaylife3.push(item.label)
      })
      everydaylife3a.map(item => {
        everydaylife3.push(item.label)
      })
      everydaylife3 = [...new Set(everydaylife3)]

      labels.value = [...everydaylife1, ...everydaylife2, ...everydaylife3]
    }

    onMounted(() => {
      if (print) {
        printLabels()
      }
    })

    return {
      isLoaded,
      updateData,
      width,
      height,
      circleData,
      showTutorial,
      labels
    }
  },
}

addIcons({
  "school-outline": schoolOutline
})
</script>

<style scoped src="@/assets/css/circlestyles.css">

</style>

<style scoped>
.list {
  font-size: 0.6rem;
}
</style>