import { createStore, createLogger } from "vuex";
import mutations from "./mutations";
import actions from "./actions";

// Should we debug?
const debug = process.env.NODE_ENV !== 'production';

// The main store
const store = createStore({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  state: {
    subjectID: null,
    participantID: null,
    participantResponses: null,
    responseID: null,
    sampleNum: null,
    sampleNumToday: null,
    referralSource: null,
    pageIsLoading: false,
    pageIsIncomplete: false,
    errorMessage: null,
    pageHasSaved: false,
    savedPages: [],
    hasModal: false,
  },
  getters: {
    /* Item data should be sent as an array of objects in the following format:
    ** { itemNum: Int!; label: String, numericVal: Float; textVal: String }
    */
    prepareResponseItemData: (state) => (payload) => {
      const preparedData = payload.map((item) => {
        return { 
          data: {
            ...item, 
            response: {
              connect: {
                id: state.responseID
              }
            } 
          }
        }
      });
      return preparedData;
    },
    pageIsSaved: (state) => (name) => state.savedPages.includes(name),
  },
  mutations,
  actions
});

export default store;
