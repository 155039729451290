<template>
    <main-layout 
    title="Three Circles" 
    slot="fixed"
    :data="circleData"
  >
    <template v-slot:toolbar>
      <ion-toolbar>
          <ion-title>Three Circles</ion-title>
          <ion-buttons slot="end">
            <ion-button color="primary" fill="clear" shape="round" size="large" @click="showTutorial">
              <ion-icon slot="start" name="school-outline"></ion-icon>Help
            </ion-button>
          </ion-buttons>
      </ion-toolbar>
    </template>
    <div id="canvas-wrapper" class="canvas-wrapper">
      <circle-canvas @updated="updateData" v-if="isLoaded" :width="width" :height="height" canvasId="circle-canvas"></circle-canvas>
      <div class="circle-info-wrapper">
        <div class="circle-info">
          <div class="blue-circle">Blue = drive</div>
          <div class="red-circle">Red = threat</div>
          <div class="green-circle">Green = soothing</div>
        </div>
      </div>
    </div>
      
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import { IonToolbar, IonTitle, IonButton, IonButtons, IonIcon } from '@ionic/vue';
import { useCircleLoader } from "@/modules/useCircleLoader"
import { useTutorial } from "@/modules/useTutorial"
import { addIcons } from 'ionicons';
import { schoolOutline  } from 'ionicons/icons';
import Modal from '@/views/Tutorial.vue'
import CircleCanvas from '@/components/CircleCanvas.vue';

const ITEM_NUM = 3;

export default {
  name: "three-circles",
  components: {
    IonToolbar, 
    IonTitle,
    IonButton, 
    IonButtons, 
    IonIcon,
    MainLayout,
    CircleCanvas,
  },
  setup() {
    const { isLoaded, updateData, width, height, circleData } = useCircleLoader('canvas-wrapper', ITEM_NUM);
    const { showTutorial } = useTutorial(Modal);

    return {
      isLoaded,
      updateData,
      width, 
      height,
      circleData,
      showTutorial
    }
  },
}

addIcons({
  "school-outline": schoolOutline
});
</script>

<style scoped src="@/assets/css/circlestyles.css">
</style>