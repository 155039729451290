import { watch, watchEffect, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { clone, get, isArray, pick } from 'lodash-es'

export default function useItemResponder(items, itemType = 'numeric') {

    const store = useStore()
    const sampleNumToday = inject('sampleNumToday')

    // Clone the reactive object (to avoid recursion errors) and watch for changes
    watch(
        () => [...items],
        (items) => {

            let pageIsIncomplete = true;
            
            if (itemType === 'numeric') {
                const numIncompleteItems = items.filter(item => item.numericVal === null).length;
                pageIsIncomplete = numIncompleteItems > 0
            }

            if (itemType === 'checkbox') {
                const numSelectedItems = items.filter(a => a.isChecked).length;
                pageIsIncomplete = numSelectedItems === 0
            }

            if (itemType === 'radio') {
                const notSelected = items.filter(a => a.textVal === null).length;
                pageIsIncomplete = notSelected > 0
            }

            if (itemType === 'complex') {

                // Item is incomplete if:
                // 1. It is marked as required; and
                // 2. item is shown to the user; and
                // 2. Both textVal and numericVal are null (default is null)
                const incompleteItems = items.filter(item => {
                    return item.isRequired && item.show &&
                        (
                            get(item, 'numericVal', null) === null && 
                            (
                                get(item, 'textVal', null) === null ||
                                get(item, 'textVal', null) === ''
                            )
                        );
                });
        
                pageIsIncomplete = incompleteItems.length > 0
            }

            store.commit("setPageIsIncomplete", pageIsIncomplete)

        },
        { deep: true, immediate: true }
    )

    const crossReferenceItemDependencies = (deps) => {
        let shouldShow;
        const refItem = items.filter(item => item.label === deps.label)[0]
  
        if (!deps.hasOwnProperty("val")) {
          shouldShow = (
            refItem.numericVal !== null && 
            (
              refItem.textVal !== null && 
              refItem.textVal !== ''
            )
          )
        } else {
          switch(refItem.type) {
            case "radio":
              shouldShow = 
                refItem.show && 
                (
                    (refItem.numericVal === deps.val) ||
                    (refItem.textVal === deps.val)
                )
              break
    
            case "checkbox":
              shouldShow = refItem.show && refItem.textVal.split("|").includes(deps.val)
              break
    
            default:
              shouldShow = false
          }
        }
        
        return shouldShow
    }

    watchEffect(() => {
        if (itemType !== 'complex') return

        items.map((item) => {
  
            const showIndicators = []
  
            // Conditional reveal items if they are set to only show for a specific sample
            if (item.hasOwnProperty("sampleOnly")) {
                showIndicators.push(item.sampleOnly === sampleNumToday.value ? true : false)
            }
          
            if (item.hasOwnProperty("dependsOn")) {
                const deps = item.dependsOn.items || item.dependsOn;
            
                if (isArray(deps)) {
                    let shouldShow
                    const showStack = []
                    deps.forEach(dep => {
                        showStack.push(crossReferenceItemDependencies(dep))
                    })
                
                    switch(item.dependsOn.join) {
                        case "or":
                        shouldShow = showStack.includes(true)
                        break
                        
                        case "and":
                        shouldShow = showStack.every(val => val === true)
                        break
                        
                        default:
                        shouldShow = false
                    }
                    showIndicators.push(shouldShow)
                } else {
                    showIndicators.push(crossReferenceItemDependencies(deps))
                }
            }
  
            item.show = showIndicators.includes(false) ? false : true
    
            // Format checkbox responses
            if (item.type === "checkbox") {
                item.textVal = item.options
                                .filter(option => option.isChecked)
                                .map(option => option.label)
                                .join("|");
            }
        });

    }); // watchEffect

    const visibleDataOnly = computed(() => {
        return clone(items)
                    .filter(item => item.show)
                    .map(item => pick(item, ['itemNum', 'label', 'description', 'numericVal', 'textVal']));
    })

    const updateVal = (e, index) => items[index].numericVal = e.target.value

    return { visibleDataOnly, updateVal }
}