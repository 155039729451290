<template>
    <main-layout 
        title="Current state" 
        :data="emotions"
    >
        <emotions-item-view :items="emotions" @itemUpdated="updateVal"></emotions-item-view>
    </main-layout>
</template>

<script>
import MainLayout from '../layouts/MainLayout.vue'
import EmotionsItemView from '@/components/EmotionsItemView.vue'
import { emotions1 } from '@/data/emotions.js'
import { defineComponent, reactive } from 'vue'

const ITEM_NUM = 4

export default defineComponent({
    name: "emotions-1",
    components: {
        MainLayout,
        EmotionsItemView,
    },
    setup() {
        const emotions = reactive(emotions1.map(item => {
            item.itemNum = ITEM_NUM;
            item.numericVal = null;
            return item;
        }));

        const updateVal = (e, index) => emotions[index].numericVal = e.target.value;

        return {
            emotions,
            updateVal,
        };
    }
});
</script>