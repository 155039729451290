<template>
    <div v-for="(emotion, index) in items" :key="emotion.label">
        <ion-list-header :mode="mode">
            <ion-label>Right now, I feel {{ emotion.description }}</ion-label>
        </ion-list-header>
        <ion-range 
            :name="emotion.label" 
            :class="[emotion.numericVal !== null ? 'has-val' : false]" 
            :mode="mode" 
            min="1" 
            max="5" 
            color="secondary" 
            step="1" 
            pin="true" 
            snaps="true" 
            @touchend='$emit("itemUpdated", $event, index)'
            @mouseup='$emit("itemUpdated", $event, index)'
        >
            <ion-text slot="start"></ion-text>
            <ion-text slot="end"></ion-text>
        </ion-range>
        <div class="range-label-wrapper ion-padding-horizontal">
            <div class="flex-label-row range-label-text">
                <div class="range-label-text">Not at all</div>
                <div class="range-label-text">Somewhat</div>
                <div class="range-label-text">Extremely</div>
            </div>
            <div class="flex-label-row range-label-text">
                <div class="range-label-text"></div>
                <div class="range-label-text">Slightly</div>
                <div class="range-label-text"></div>
                <div class="range-label-text">Moderately</div>
                <div class="range-label-text"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, inject } from 'vue'
import { IonRange, IonText, IonListHeader, IonLabel } from '@ionic/vue'
import useItemResponder from '@/modules/useItemResponder'

export default defineComponent({
    name: "emotions-1",
    components: {
        IonRange,
        IonText,
        IonListHeader,
        IonLabel,
    },
    props: ['items'],
    emits: ['itemUpdated'],
    setup(props) {
        const mode = inject("mode")

        useItemResponder(props.items, 'numeric')

        return {
            mode,
        };
    }
});
</script>