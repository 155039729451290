import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client/core";
import { GRAPHQL_ENDPOINT } from './config'

const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT
})

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
})