import { reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import usePageManager from '@/modules/usePageManager'

export default function useLogin(options) {

    const route = useRoute()
    const store = useStore()
    const { nextPage } = usePageManager()

    const defaults = {
        subjectID: route.query.subjectid.toLowerCase(),
        condition: route.query.c || null,
        referralSource: route.query.source || null,
        day: parseInt(route.query.day) || 99,
        sample: parseInt(route.query.sample) || 99,
        samplesDay: parseInt(route.query.samplesday) || 99,
        samplesDone: parseInt(route.query.samplesdone) || 99,
        samplesDoneDay: parseInt(route.query.samplesdoneday) || 99,
    }

    const queries = reactive({
        ...defaults,
        ...options
    })

    const startNewSession = () => {
        store.dispatch('createSession', queries)
            .then(() => nextPage())
            .catch((e) => console.log(e))
    }
    return {
        startNewSession,
        queries
    }
}