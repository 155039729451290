<template>
  <ion-page>
    <ion-content fullscreen class="ion-padding-horizontal">
      <div id="wrapper">
        <div id="wrapper-inner">
          <h2 class="ion-text-center ion-margin-bottom">Saving responses...</h2>
          <p class="ion-text-center">Redirecting in {{ counter }}</p>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { onMounted, inject, ref, computed } from "vue"
import { useStore } from "vuex"
import { useRoute } from "vue-router"
import { IonPage, IonContent } from "@ionic/vue"

const prolificCompletionUrls = {
  partOne: "https://uqpsych.qualtrics.com/jfe/form/SV_8p5ZPeaeTx7CIeO",
  partTwo: "https://uqpsych.qualtrics.com/jfe/form/SV_beCat2f1OL5MFw2",
}
const dylanCompletionUrl =
  "https://uqpsych.qualtrics.com/jfe/form/SV_8p5ZPeaeTx7CIeO"
const dylanTeenCompletionUrl =
  "https://uqpsych.qualtrics.com/jfe/form/SV_bCLwKZYFvoprRqK"

export default {
  name: "Thankyou-Page",
  components: {
    IonPage,
    IonContent,
  },
  setup() {
    const store = useStore()
    const showProgressBar = inject("showProgressBar")
    const updateProgress = inject("updateProgress")
    const subjectID = inject("subjectID")
    const sampleNum = inject("sampleNum")
    const referralSource = computed(() => store.state.referralSource)
    const counter = ref(4)
    const route = useRoute()

    const redirectLink = computed(() => {
      const redirectUrlBase = route.meta.redirectUrlBase

      if (
        referralSource.value === "prolific" &&
        (sampleNum.value === 1 || sampleNum.value === "1")
      ) {
        return prolificCompletionUrls.partOne
      }

      if (
        referralSource.value === "prolific" &&
        (sampleNum.value === 2 || sampleNum.value === "2")
      ) {
        return prolificCompletionUrls.partTwo
      }

      if (referralSource.value === "dylan") {
        return dylanCompletionUrl
      }

      if (referralSource.value === "dylanteenstudy") {
        return dylanTeenCompletionUrl
      }

      return `${redirectUrlBase}?subjectid=${subjectID.value}&sample=${sampleNum.value}`
    })

    onMounted(() => {
      showProgressBar(true)
      updateProgress(1)
      countdown()
    })

    let countdownTimer
    const countdown = () => {
      if (counter.value === 0) {
        clearTimeout(countdownTimer)
        window.location.replace(redirectLink.value)
      } else {
        countdownTimer = setTimeout(() => {
          counter.value--
          countdown()
        }, 1000)
      }
    }

    return {
      counter,
      countdown,
      redirectLink,
    }
  },
}
</script>

<style scoped>
#wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  align-items: center;
}
</style>
