<template>
  <canvas :id="canvasId" :width="width" :height="height"></canvas>
</template>

<script>
import { defineComponent } from "vue"
const createjs = window.createjs || {}
const gsap = window.gsap || {}

const getCircleData = (newDims, oldDims, data) => {
  return data.circles.map((circle) => {
    const { x, y } = circle.pos(data.width, data.height)
    return {
      obj: circle.obj || null,
      props: {
        ...data.defaults,
        ...circle.props,
        x,
        y,
      },
      pos: circle.pos,
    }
  })
}

export default defineComponent({
  name: "CircleCanvas",
  props: ["width", "height", "canvasId"],
  data() {
    return {
      canvas: null,
      stage: null,
      minRadius: 20,
      maxRadius: 200,
      circles: [
        {
          obj: null,
          props: {
            label: "blue_circle",
            fill: "#58AFDB",
          },
          pos: (width, height) => ({ x: width / 3, y: height / 3 }),
        },
        {
          obj: null,
          props: {
            label: "green_circle",
            fill: "#73D5B7",
          },
          pos: (width, height) => ({ x: 2 * (width / 3), y: height / 3 }),
        },
        {
          obj: null,
          props: {
            label: "red_circle",
            fill: "#DD4D73",
          },
          pos: (width, height) => ({ x: width / 2, y: 2 * (height / 3) }),
        },
      ],
    }
  },
  created() {
    this.$watch(
      () => ({ width: this.width, height: this.height }),
      (newVals, oldVals) => {
        this.circles = getCircleData(newVals, oldVals, this)
        this.redraw()
        this.$emit("updated", this.formatData())
      },
      { immediate: true }
    )
  },
  computed: {
    defaults: function () {
      return {
        radius: this.width / 10,
        stroke: null,
        xAnchor: 0,
        yAnchor: 0,
        isActive: false,
        isHovered: false,
      }
    },
  },
  emits: ["updated"],
  methods: {
    loadStage() {
      this.setupCanvas()
      this.drawCircles()

      // Setup a "tick" event listener so that the EaselJS stage gets updated on every frame/tick
      gsap.ticker.add(this.updateStage)
      this.stage.update()
    },
    updateStage() {
      this.stage.update()
    },
    setupCanvas() {
      if (
        Object.keys(createjs).length === 0 ||
        Object.keys(gsap).length === 0
      ) {
        setTimeout(this.setupCanvas, 1000) // Try again in a second if libraries haven't loaded
      } else {
        this.canvas = document.getElementById(this.canvasId)
        this.stage = new createjs.Stage(this.canvas)

        if (createjs.Touch.isSupported()) {
          createjs.Touch.enable(this.stage, false, false)
        } else {
          this.stage.enableMouseOver(20)
        }
      }
    },
    drawCircles() {
      for (let i = 0; i < this.circles.length; i++) {
        this.circles[i].obj = this.createCircle(i)
        this.stage.addChild(this.circles[i].obj)
        this.redraw()
      }
    },
    createCircle(index) {
      const circle = new createjs.Shape(),
        props = this.circles[index].props,
        g = circle.graphics

      // Set name
      circle.name = props.label

      g.setStrokeStyle(3)
      g.beginFill(props.fill)
      props.stroke = g.beginStroke(createjs.Graphics.getRGB(0, 0, 0, 0)).command
      g.drawCircle(0, 0, props.radius)

      // In order for the ColorFilter to work, we must cache() the circle
      // circle.cache(-100, -100, 200, 200);

      // Place the circle
      // circle.x = props.x
      // circle.y = props.y

      // Add event listeners
      circle.on("mouseover", () => this.handleMouseOver(index))
      circle.on("mouseout", () => this.handleMouseOut(index))
      circle.on("mousedown", (evt) => this.handleMouseDown(evt, index))
      circle.on("pressup", (evt) => this.handleMouseUp(evt, index))
      circle.on("pressmove", (evt) => this.handleMouseMove(evt, index))

      return circle
    },
    redraw: function () {
      // Place the circles
      this.circles.map((circle) => {
        if (!circle.obj) return circle
        circle.obj.x = circle.props.x
        circle.obj.y = circle.props.y
      })
    },
    highlight: function (index) {
      this.circles[index].props.stroke.style = createjs.Graphics.getRGB(
        0,
        0,
        0,
        0.5
      )
    },
    unhighlight: function (index) {
      this.circles[index].props.stroke.style = createjs.Graphics.getRGB(
        0,
        0,
        0,
        0
      )
    },
    handleMouseOver: function (index) {
      this.circles[index].props.isHovered = true
      const numActive = this.circles.filter(
        (circle) => circle.props.isActive
      ).length
      if (numActive == 0 || this.circles[index].props.isActive) {
        this.highlight(index)
      }
    },
    handleMouseOut: function (index) {
      this.circles[index].props.isHovered = false
      if (!this.circles[index].props.isActive) {
        this.unhighlight(index)
      }
    },
    handleMouseDown: function (evt, index) {
      if (!evt.primary) return false
      evt.nativeEvent.preventDefault()
      this.selectCircle(evt, index)
    },
    handleMouseUp: function (evt, index) {
      if (!evt.primary) return false
      this.unSelectCircle(evt, index)
    },
    handleMouseMove: function (evt, index) {
      if (!evt.primary) return false
      const { radius, xAnchor, yAnchor } = this.circles[index].props
      const xDiff = evt.localX - xAnchor,
        yDiff = -evt.localY - yAnchor

      let xyControl = xAnchor,
        xyDiff = xDiff

      if (Math.abs(xAnchor) < Math.abs(yAnchor)) {
        xyControl = yAnchor
        xyDiff = yDiff
      }

      xyDiff = xyControl < 0 ? -xyDiff : xyDiff
      const newRadius = radius + xyDiff // Invert to match normal cartesian plane coordinates
      evt.target.graphics.command.radius = Math.min(
        Math.max(newRadius, this.minRadius),
        this.maxRadius
      )
    },
    selectCircle: function (evt, index) {
      let props = this.circles[index].props

      // Set the current properties
      props.radius = evt.target.graphics.command.radius
      props.xAnchor = evt.localX
      props.yAnchor = -evt.localY // Invert to match normal cartesian plane coordinates

      // Add a border around the circle being tapped/clicked
      this.highlight(index)
      this.circles.map((circle) => (circle.props.isActive = false))
      this.circles[index].props.isActive = true

      // Ensure the latest circle to be interacted is on top in the visual hierarchy
      this.stage.setChildIndex(
        this.stage.getChildByName(props.label),
        this.stage.numChildren - 1
      )
    },
    unSelectCircle: function (evt, index) {
      let props = this.circles[index].props
      props.radius = evt.target.graphics.command.radius
      this.circles[index].props.isActive = false
      if (!this.circles[index].props.isHovered) {
        this.unhighlight(index)
        const hoveredIndex = this.circles.findIndex(
          (circle) => circle.props.isHovered
        )
        if (hoveredIndex >= 0) this.highlight(hoveredIndex)
      }

      this.$emit("updated", this.formatData())
    },
    formatData: function () {
      return this.circles.map((circle) => {
        return {
          label: circle.props.label,
          numericVal:
            (circle.props.radius - this.minRadius) /
            (this.maxRadius - this.minRadius),
        }
      })
    },
  },
  mounted: function () {
    this.loadStage()
    this.$emit("updated", this.formatData())
  },
  unmounted: function () {
    createjs.Touch.disable(this.stage)
    this.circles.forEach((circle) => circle.obj?.removeAllEventListeners())
  },
  destroy() {
    createjs.Touch.disable(this.stage)
    this.circles.forEach((circle) => circle.obj?.removeAllEventListeners())
  },
})
</script>
