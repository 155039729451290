<template>

    <div v-for="(item, index) in items || []" :key="item.itemNum">
      
        <ion-list v-if="item.type === 'radio' && item.show" :mode="mode">
            <ion-radio-group v-if="item.hasOwnProperty('numericVal')" v-model="item.numericVal">
                <ion-list-header>
                    <ion-label class="ion-text-wrap">{{ item.description }}</ion-label>
                </ion-list-header>
                <ion-item v-for='option in item.options' :key="option.val">
                    <ion-label class="ion-text-wrap">{{ option.description }}</ion-label>
                    <ion-radio slot="start" :value="option.val"></ion-radio>
                </ion-item>
            </ion-radio-group>
            <ion-radio-group v-if="item.hasOwnProperty('textVal')" v-model="item.textVal">
                <ion-list-header>
                    <ion-label class="ion-text-wrap">{{ item.description }}</ion-label>
                </ion-list-header>
                <ion-label class="sub-label" color="tertiary" :mode="mode" v-if="item.hasOwnProperty('description2')">{{ item.description2 }}</ion-label>
                <ion-item v-for='option in item.options' :key="option.val">
                    <ion-label class="ion-text-wrap">{{ option.description }}</ion-label>
                    <ion-radio slot="start" :value="option.val"></ion-radio>
                </ion-item>
            </ion-radio-group>
        </ion-list>

        <ion-list v-if="item.type === 'textarea' && item.show" :mode="mode">
            <ion-list-header>
                <ion-label class="ion-text-wrap">{{ item.description }}</ion-label>
            </ion-list-header>
            <ion-item>
                <ion-textarea rows="6" :auto-grow="true" enterkeyhint="done" inputmode="text" placeholder="Type here..." v-model="item.textVal"></ion-textarea>
            </ion-item>
        </ion-list>
                    
        <ion-list v-if="item.type === 'checkbox' && item.show" :mode="mode">
            <ion-list-header>
            <ion-label class="ion-text-wrap">{{ item.description }}</ion-label>
            </ion-list-header>
            <ion-item v-for="option in item.options" :key="option.label">
            <ion-label class="ion-text-wrap">{{ option.description }}</ion-label>
            <ion-checkbox 
                slot="start"
                @update:modelValue="option.isChecked = $event"
                :modelValue="option.isChecked">
            ></ion-checkbox>
            </ion-item>
        </ion-list>

        <ion-list v-if="item.type === 'slider' && item.show" :mode="mode">
            <ion-list-header>
                <ion-label class="ion-text-wrap">{{ item.description }}</ion-label>
            </ion-list-header>
            <ion-range 
                :name="item.label" 
                :class="[item.numericVal !== null ? 'has-val' : false]" 
                :mode="mode" 
                min="1" 
                max="5" 
                color="secondary" 
                step="1" 
                pin="true" 
                snaps="true" 
                @touchend='$emit("itemUpdated", $event, index)'
                @mouseup='$emit("itemUpdated", $event, index)'
            >
                <ion-text slot="start"></ion-text>
                <ion-text slot="end"></ion-text>
            </ion-range>
            <div class="range-label-wrapper ion-padding-horizontal">
                <div class="flex-label-row range-label-text">
                    <div class="range-label-text" v-for="option in item.options" :key="option">{{ option }}</div>
                </div>
            </div>
        </ion-list>

    </div>

</template>

<script>
import { IonList, IonItem, IonLabel, IonRadio, IonRadioGroup, IonListHeader, IonTextarea, IonText, IonCheckbox, IonRange } from '@ionic/vue'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'questionnaire-item-view',

  components: {
    IonTextarea,
    IonText,
    IonList, 
    IonItem, 
    IonRadio,
    IonRadioGroup,
    IonListHeader,
    IonCheckbox,
    IonLabel,
    IonRange
  },

    props: ["items"],

    emits: ['itemUpdated'],

    setup() {
        const mode = inject("mode")

        return {
            mode
        }
    },
});

</script>

<style>
.flex-label-row {
  display: flex;
  justify-content: space-between;
}

.sub-label {
    font-size: 0.8rem;
    margin-left: 1.2rem;
    margin-bottom: 0.8rem;
    display: block;
}
</style>