<template>
  <validation-layout 
    title="Current activities" 
    :data="activityData"
  >
    <activities-item-view :itemNum="ITEM_NUM" @itemsUpdated="updateSelectedItems"></activities-item-view>
  </validation-layout>
</template>

<script>
import ValidationLayout from "@/layouts/ValidationLayout.vue"
import { defineComponent, ref } from 'vue'
import ActivitiesItemView from '@/components/ActivitiesItemView.vue'

const ITEM_NUM = 2;

export default defineComponent({
  name: 'activities-page',
  components: {
    ValidationLayout,
    ActivitiesItemView,
  },
  setup() {
    const activityData = ref(null)
    const updateSelectedItems = (data) => activityData.value = data

    return {
      activityData,
      updateSelectedItems,
      ITEM_NUM,
    }
  },
})

</script>