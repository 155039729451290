<template>
  <main-layout 
    title="Compassion in everyday life (cont'd)"
    :data="visibleDataOnly"
  >
    <questionnaire-item-view :items="items" @itemUpdated="updateVal"></questionnaire-item-view>
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/MainLayout.vue'
import { defineComponent, reactive } from 'vue'
import { everydaylife2 } from '@/data/everydaylife.js'
import useItemResponder from '@/modules/useItemResponder'
import QuestionnaireItemView from '@/components/QuestionnaireItemView.vue'

export default defineComponent({
  name: 'everyday-life-2',
  components: {
    MainLayout,
    QuestionnaireItemView
  },
  setup() {
    const items = reactive(everydaylife2);
    const { visibleDataOnly } = useItemResponder(items, 'complex')

    const updateVal = (e, index) => items[index].numericVal = e.target.value

    return {
      items,
      visibleDataOnly,
      updateVal
    }
  },
})

</script>