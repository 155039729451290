<template>
  <main-layout 
    title="Sleep Quality" 
    :data="data"
  >
      <div class="ion-margin-top image-wrapper">
        <img src="@/assets/img/cartoon-person-in-bed.jpg" />
        <ion-text><p class="question-text">{{ data[0].description }}</p></ion-text>
      </div>
      <ion-range 
        name="sleep"
        :class="[data[0].numericVal !== null ? 'has-val' : false]" 
        :mode="mode" 
        min="1" 
        max="10" 
        color="secondary" 
        step="1" 
        pin="true" 
        snaps="true" 
        @ionChange="updateVal"
      ></ion-range>
      <ion-grid>
        <ion-row>
          <ion-col size="3" class="ion-text-start">Terrible</ion-col>
          <ion-col size="2" class="ion-text-start">Poor</ion-col>
          <ion-col size="2" class="ion-text-center">Fair</ion-col>
          <ion-col size="2" class="ion-text-end">Good</ion-col>
          <ion-col size="3" class="ion-text-end">Excellent</ion-col>
        </ion-row>
      </ion-grid>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayoutV2.vue'
import { IonRange, IonText, IonCol, IonGrid, IonRow } from '@ionic/vue'
import { defineComponent, reactive, inject } from 'vue'
import useItemResponder from '@/modules/useItemResponder'

const ITEM_NUM = 1;

export default defineComponent({
  name: 'sleep-page',
  components: {
    MainLayout,
    IonRange,
    IonText,
    IonCol,
    IonGrid,
    IonRow
  },
  setup() {   
    const mode = inject("mode")
    const data = reactive([{
      itemNum: ITEM_NUM,
      label: "sleep",
      numericVal: null,
      description: "Overall, how would you rate the quality of your sleep last night?"
    }])

    const updateVal = (slider) => data[0].numericVal = slider.detail.value;

    useItemResponder(data, 'numeric')

    return {
      mode,
      data,
      updateVal,
    }
  }
});
</script>

<style scoped>
.image-wrapper {
  text-align: center;
}

img {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}
</style>