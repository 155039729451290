import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "./apollo";
import { IonicVue } from "@ionic/vue";
import YoutubeIframe from '@techassi/vue-youtube-iframe';
import VueGtag from "vue-gtag-next";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Set up the app instance */
const app = createApp(App)
  .provide(DefaultApolloClient, apolloClient)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(YoutubeIframe)
  .use(VueGtag, {
    property: {
      id: "G-88NZVLPJZ2",
    },
    useDebugger: false
  });

/* Mount the app */
router.isReady().then(() => {
  app.mount("#app");
});

export default app;
