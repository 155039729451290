import {modalController } from '@ionic/vue';

function useTutorial(component) {
    const showTutorial = async () => {
        const modal = await modalController
          .create({
            component: component,
            cssClass: 'my-custom-class',
          });
        return modal.present();
      }

    return {
        showTutorial
    }
}

export { useTutorial }