<template>
    <ion-page>
        <ion-content>
            <youtube-iframe 
                  :video-id="videoId" 
                  :no-cookie="true"
                  :player-width="`100%`"
                  :player-height="`300px`"
                  :player-parameters="{ host: `${proto}//www.youtube.com` }">
            </youtube-iframe>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle>Understanding our three emotion systems</ion-card-subtitle>
              <ion-card-title>Three Circles: A brief introduction</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-row>
                <ion-text id="tutorial-text">
                  <p>This simple app is about your 'three circles'. In other words, your three main emotion systems: Threat, Drive and Soothing.</p>
                  <p>If you haven't already, watch the video above for a brief (&lt;3 mins) overview of what these mean.</p>
                  <h3>What next?</h3>
                  <p>When you are ready, close this popup. You will see three circles on the screen (red, blue and green). The size of the circles represents how much of each system is relevant for you <em>right now</em> (Page 1), and generally <em>during the last week</em> (Page 2). You can change the size of each circle to reflect how you have been feeling using a simple click (or tap) and drag.</p>
                  <p>There's no right or wrong answer, so don't worry about getting them exactly right. Just go with your instinct.</p>
                  <h3>Moving on</h3>
                  <p>When you are happy with the size of your three circles, click 'continue' at the bottom to move on. You only need to resize your circles twice (Pages 1 and 2) to complete this study.</p>
                  <h3>Who can I contact about this study?</h3>
                  <p>This is part of a larger study being conducted by Dr James Kirby and Dr Jamin Day from the Compassionate Mind Research Group at the University of Queensland, Australia.</p>
                  <p>If you have any questions about it, contact Dr James Kirby at (<a href="mailto:j.kirby@psy.uq.edu.au">j.kirby@psy.uq.edu.au</a>).</p>
                </ion-text>
              </ion-row>
              <ion-row class="ion-padding-top">
                <ion-button @click="closeModal">
                  <ion-icon name="check" slot="start"></ion-icon>Close
                </ion-button>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonButton, IonRow, IonIcon, IonText, modalController } from '@ionic/vue';
import { addIcons } from 'ionicons';
import { checkmarkOutline  } from 'ionicons/icons';

export default {
  name: "Intro-Page",
  components: {
    IonPage, 
    IonContent, 
    IonCard, 
    IonCardHeader, 
    IonCardTitle, 
    IonCardSubtitle,
    IonCardContent,
    IonButton,
    IonRow,
    IonIcon, 
    IonText,
  },
  setup() {
    const proto = window.location.protocol;
    const videoId = "CUBloap2ixI";

    const closeModal = () => {
      return modalController.dismiss();
    };

    return {
      proto,
      videoId,
      closeModal
    }
  },
}

addIcons({
  check: checkmarkOutline
});
</script>

<style scoped>

#tutorial-text > h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1.4rem;
  margin-bottom: 6px;
} 

#tutorial-text > p {
  margin-bottom: 1.2rem;
  font-size: 1rem;
}
  

</style>