import { inject, computed, watchEffect, onMounted } from 'vue';
import { get, isNull, findIndex, isArray } from "lodash-es";
import { useStore } from 'vuex';
import { alertController } from '@ionic/vue';
import { useRoute, useRouter, isNavigationFailure, NavigationFailureType } from 'vue-router';

export default function usePageManager(data) {
    const store = useStore()

    // UI 
    const showProgressBar = inject("showProgressBar")
    const updateProgress = inject('updateProgress')
    const hasModal = computed(() => store.state.hasModal)

    // Router
    const route = useRoute()
    const router = useRouter()
    const routes = computed(() => {
        const routes = router.options.routes
        
        // Check if routes declare the progress bar should not be shown;
        // otherwise set 'useProgressBar' to true
        routes.map(route => {
            if (isNull(get(route, ["meta", "useProgressBar"], null))) {
                route.meta = route.meta || {}
                route.meta.useProgressBar = true
            }
        })

        return routes
    })
    const currentSequence = route.meta?.sequence || ''
    const currentSequenceRoutes = computed(() => routes.value.filter(route => route.meta?.sequence === currentSequence))

    // Pages
    const currentIndex = findIndex(currentSequenceRoutes.value, ['name', route.name])
    const sampleNumToday = computed(() => store.state.sampleNumToday || parseInt(route.query.samplesday))
    const currentPercent = computed(() => currentIndex / (currentSequenceRoutes.value.length - 1))
    const nextPageInSequence = computed(() => {
        let r = currentSequenceRoutes.value
        let nextRoute = r[currentIndex + 1]

        // Skip the next route in the sequence if it's not meant to show for this sample.
        if (nextRoute.meta.hasOwnProperty("sampleOnly") && sampleNumToday.value !== nextRoute.meta.sampleOnly) {
            nextRoute = r[currentIndex + 2]
        }
        
        return nextRoute.name
    })

    onMounted(() => {
        showProgressBar(true)
        updateProgress(currentPercent.value)
        
        // Ensure the page is scrolled to the top
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })  
    });

    // Always send an array of data to the server
    const responseData = computed(() => {
        return isArray(data.value) ? data.value : [data.value]; 
    })

    // If the next requested route is the last one in the sequence,
    // call router.replace. Otherwise push the next route onto the stack.
    const nextPage = (routeName) => {
        routeName = routeName || nextPageInSequence.value

        const replace = currentIndex === currentSequenceRoutes.value.length - 1
        
        router.push({ name: routeName, replace: replace }).catch(failure => {
            if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
                console.log("Navigation was redirected");
            } else if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
                console.log("Navigation was aborted");
            } else {
                console.log("Unspecified navigation error");
            }
        })
    }

    // Page progression logic
    const saveAndContinue = () => {
        store.dispatch("saveResponseItems", responseData.value).then(() => {
            store.commit("storeSavedPage", route.name)
            nextPage()
        }).catch(e => console.log(e))
    }

    // If vuex indicates we should show the modal, show it
    watchEffect(() => {
        if (hasModal.value === route.name) {
            store.commit("setHasModal", false);
            backWarningModal();
        }
    });

    // Controller for the modal
    const backWarningModal = async () => {
        const alert = await alertController.create({
        header: 'Action not available',
        message: 'Going back has been disabled.<br><br>Press continue to return to the page you were on.',
        buttons: [
                {
                text: 'Continue',
                role: 'cancel',
                },
            ]
        });

        await alert.present();
        alert.onDidDismiss()
            .then(() => nextPage());
    }

    return {
        saveAndContinue,
        nextPage,
    }

}
