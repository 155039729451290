import { gql } from "@apollo/client/core";

export const ALL_PARTICIPANTS = gql`
  query getParticipants {
    allParticipants {
      id
      subjectID
    }
  }
`;

export const GET_PARTICIPANT_BY_CODE = gql`
  query getParticipant($subjectID: String!, $referralSource: String, $includeResponses: Boolean = false) {
    allParticipants(where: { subjectID: $subjectID, referralSource: $referralSource }, first: 1) {
      id
      subjectID
      referralSource
      responses(sortBy: sample_DESC)@include(if: $includeResponses) {
        id
        day
        sample
        samplesDay
        samplesDone
        samplesDoneDay
        createdAt
      }
    }
  }
`;

export const CREATE_PARTICIPANT = gql`
  mutation createParticipant($subjectID: String!, $condition: String, $referralSource: String) {
    createParticipant(data: { subjectID: $subjectID, condition: $condition, referralSource: $referralSource }) {
      id
    }
  }
`;

export const CREATE_RESPONSE_AS_PARTICIPANT = gql`
  mutation createResponse(
      $participantID: ID! 
      $day: Int! 
      $sample: Int! 
      $samplesDay: Int
      $samplesDone: Int
      $samplesDoneDay: Int
    ) {
    createResponse(data: {
      day: $day,
      sample: $sample,
      samplesDay: $samplesDay,
      samplesDone: $samplesDone,
      samplesDoneDay: $samplesDoneDay, 
      participant: {
        connect: {
          id: $participantID
        }
      } 
    }) {
      id
      day
      sample
      samplesDay
    }
  }
`;

export const SAVE_RESPONSE_DATA = gql`
  mutation createResponseItems($data: [ResponseItemsCreateInput]!) {
    createResponseItems(data: $data) {
      id
    }
  }
`;