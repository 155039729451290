<template>
    <ion-page>
        <ion-content fullscreen class="ion-padding-horizontal">
            <div id="wrapper">
                <div id="wrapper-inner">
                    <h2 class="ion-text-center ion-margin-bottom">Thank you!</h2>
                    <p class="ion-text-center">Your responses have been saved.</p>
                    <p class="ion-text-center">We appreciate your participation in this study.</p>
                    <p class="ion-text-center">You can now close this browser window.</p>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { onMounted, inject } from 'vue';
import { IonPage, IonContent } from '@ionic/vue';

export default {
    name: "End-Page",
    components: {
        IonPage,
        IonContent
    },
    setup() {
        const showProgressBar = inject("showProgressBar");
        const updateProgress = inject('updateProgress');

        onMounted(() => {
            showProgressBar(true);
            updateProgress(1);
        });
       
        return {};
    }
    
}
</script>

<style scoped>
#wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 1;
    align-items: center;
}
</style>