<template>
  <main-layout 
    title="Compassion in everyday life"
    :data="visibleDataOnly"
  >
    <questionnaire-item-view :items="items" @itemUpdated="updateVal"></questionnaire-item-view>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayoutV2.vue";
import useItemResponder from '@/modules/useItemResponder'
import { defineComponent, reactive } from 'vue';
import { everydaylife1 } from "@/data/v2/everydaylife.js";
import QuestionnaireItemView from '@/components/QuestionnaireItemView.vue'

export default defineComponent({
  name: 'everyday-life-1',
  components: {
    MainLayout,
    QuestionnaireItemView
  },
  setup() {
    const items = reactive(everydaylife1)
    const { updateVal, visibleDataOnly } = useItemResponder(items, 'complex')
    
    return {
      items,
      visibleDataOnly,
      updateVal
    }
  },
});

</script>