import { ref, onBeforeMount, onMounted, onUnmounted } from "vue"
import { debounce } from "lodash-es"

function useCircleLoader(wrapperId, itemNum, labelId = null) {
  const isLoaded = ref(false)
  const width = ref(0)
  const height = ref(0)
  const wrapper = ref(null)

  let circleData = ref([])
  let debouncedResize

  const getDimensions = () => {
    setTimeout(() => {
      if (wrapper.value) {
        if (!isLoaded.value) {
          isLoaded.value = true
        }

        const { width: canvasWidth, height: canvasHeight } =
          wrapper.value.getBoundingClientRect()
        width.value = canvasWidth
        height.value = canvasHeight
      } else {
        getDimensions()
      }
    }, 500)
  }

  const resize = () => getDimensions()

  onBeforeMount(() => {
    debouncedResize = debounce(resize, 250)
    window.addEventListener("resize", debouncedResize, false)
  })

  onMounted(() => {
    console.log(`Circle wrapper ${wrapperId} mounted`)
    wrapper.value = document.getElementById(wrapperId)
    getDimensions()
  })

  onUnmounted(() => {
    window.removeEventListener("resize", debouncedResize, false)
  })

  const updateData = (data) => {
    data.map((item) => {
      item.itemNum = itemNum
      item.label = labelId ? item.label + "_" + labelId : item.label
      return item
    })
    circleData.value = data
    console.log(circleData.value)
  }

  return {
    isLoaded,
    updateData,
    width,
    height,
    circleData,
  }
}

export { useCircleLoader }
