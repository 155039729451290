<template>
    <validation-layout 
        title="Current state" 
        :data="emotions"
    >
        <emotions-item-view :items="emotions" @itemUpdated="updateVal"></emotions-item-view>
    </validation-layout>
</template>

<script>
import ValidationLayout from '@/layouts/USValidationLayout.vue'
import EmotionsItemView from '@/components/EmotionsItemView.vue'
import { emotions1 } from "@/data/emotions.js";
import { defineComponent, reactive } from 'vue';

const ITEM_NUM = 5

export default defineComponent({
    name: "emotions-us",
    components: {
        ValidationLayout,
        EmotionsItemView,
    },
    setup() {
        const emotions = reactive(emotions1.map(item => {
            item.itemNum = ITEM_NUM;
            item.numericVal = null;
            return item;
        }).filter(item => item.label !== "need_recuperate"));

        const updateVal = (e, index) => emotions[index].numericVal = e.target.value;

        return {
            emotions,
            updateVal,
        };
    }
});
</script>