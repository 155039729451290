<template>
    <ion-page>
        <ion-content fullscreen class="ion-padding-horizontal">
            <div class="centered">
                <div>
                    <h1>Welcome!</h1>
                    <h2>Your code is:</h2>
                    <ion-text class="text-large">{{ queries.subjectID.toUpperCase() }}</ion-text>
                    <ion-button expand="block" size="large" class="ion-margin-vertical" @click="startNewSession" :disabled="pageIsLoading">
                        <ion-spinner name="dots" v-if="pageIsLoading"></ion-spinner>
                        <ion-text v-else>Continue</ion-text>
                    </ion-button>
                    <div v-if="errorMessage" class="ion-margin-top login-note">
                        <ion-text class="ionic-margin-vertical" color="danger">{{ errorMessage }}</ion-text>
                    </div>
                    <div v-else class="ion-margin-top login-note">
                        <ion-text>
                            <p>Response #{{ queries.sample }}</p>
                            <p class="text-small">Note: This link will expire after you click 'continue'. Please complete the survey all the way to the end in one sitting.</p>
                            <p class="text-small">If you quit or refresh your browser, you will be unable to return to this response.</p>
                        </ion-text>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { at } from 'lodash-es'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { IonContent, IonPage, IonButton, IonSpinner, IonText } from '@ionic/vue'
import useLogin from '@/modules/useLogin'


export default {
    name: "Login-Page",
    components: {
        IonContent, 
        IonPage, 
        IonButton,
        IonText,
        IonSpinner
    },
    beforeRouteEnter(to, _, next) {
        const queryVars = at(to.query, ['subjectid', 'day', 'sample', 'samplesday'])
        if (queryVars.includes(undefined)) {
            next({ name: "NoAccess", query: { ...to.query } })
        } else {
            next()
        }
    },
    setup() {
        const store = useStore()
        const { queries, startNewSession } = useLogin({
            referralSource: "ema.v2"
        })
    
        return {
            pageIsLoading: computed(() => store.state.pageIsLoading),
            errorMessage: computed(() => store.state.errorMessage),
            participantID: computed(() => store.state.participantID),
            startNewSession,
            queries
        }
    },
}

</script>

<style scoped src="@/assets/css/loginstyles.css">
</style>