import { reactive, toRefs } from "vue";

function useProgress() {
  
  const state = reactive({
    progress: 0,
    shouldShowProgressBar: false
  })
  
  const updateProgress = function(newVal) {
    state.progress = newVal;
  }

  const showProgressBar = function(val) {
    state.shouldShowProgressBar = val;
  }

  return { ...toRefs(state), updateProgress, showProgressBar };
}

export { useProgress };