export default {
    setPageIsLoading(state, val) {
        state.pageIsLoading = val;
    },
    setPageIsIncomplete(state, val) {
        state.pageIsIncomplete = val;
    },
    setSubjectID(state, id) {
        state.subjectID = id;
    },
    setParticipantID(state, id) {
        state.participantID = id;
    },
    setParticipantResponses(state, data) {
        state.participantResponses = data;
    },
    setResponseID(state, id) {
        state.responseID = id;
    },
    setSampleNum(state, num) {
        state.sampleNum = num;
    },
    setSampleNumToday(state, num) {
        state.sampleNumToday = num;
    },
    setReferralSource(state, source) {
        state.referralSource = source;
    },
    setHasModal(state, val) {
        state.hasModal = val;
    },
    setErrorMessage(state, msg) {
        state.errorMessage = msg;
    },
    clearErrorMessage(state) {
        state.errorMessage = null;
    },
    storeSavedPage(state, name) {
        state.savedPages.push(name);
    },
    setOnLoadPageIsSaved(state) {
        state.pageHasSaved = true;
    },
    clearOnLoadPageIsSaved(state) {
        state.pageHasSaved = false;
    }
}